import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import React, {memo} from 'react';

// //@ts-ignore
// import ARROWUP from '../../../assets/restuarant/arrow-up.svg';
// //@ts-ignore
// import ARROWDOWN from '../../../assets/restuarant/arrow-down.svg';
import { MECHANFO_FONT_REGULAR } from '../import';
import ARROWDOWN from '../../components/SVG/ARROWDOWN';
import ARROWUP from '../../components/SVG/ARROWUP';

const PListItem = ({cat_id, isExpanded, onPress, categoryName, count}:any) => {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={()=>{ onPress(cat_id) }}
      style={styles.header}>
      <Text style={styles.headerText}>
        {categoryName} ({count})
      </Text>
      {isExpanded ? <ARROWUP /> : <ARROWDOWN />}
    </TouchableOpacity>
  );
};

export default memo(PListItem);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F4F5FA',
  },
  headerText: {
    fontSize: 16,
    fontWeight: '600',
    color: 'rgba(58, 58, 58, 1)',
  },
  header: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: 20,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  itemTextStyle: {
    fontFamily: MECHANFO_FONT_REGULAR,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#6E7070',
  },
});
