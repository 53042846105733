import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const SvgComponent = (props: SvgProps) => (
  <Svg
  //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <Path
      fill="#3A3A3A"
      d="m8.911 13.624 3.79-5.05h5.61c.96 0 1.44 1.16.76 1.84l-5.18 5.18c-.83.83-2.18.83-3.01 0l-1.97-1.97Z"
    />
    <Path
      fill="#3A3A3A"
      d="M6.471 8.574h6.23l-3.79 5.05-3.21-3.21c-.67-.68-.19-1.84.77-1.84Z"
    />
  </Svg>
)
export default SvgComponent
