import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { base_url } from '../../api/api';
import { Platform } from 'react-native';

export const getRestaurantdetailsData = async (
  cb: any,
  rest_id: any,
  userId: any,
) => {

  let restaurant_deatils: any = [];
  let hour = new Date().getHours();
  let minute = new Date().getMinutes();
  let date = new Date().getDate(); //Current Date
  let month = new Date().getMonth() + 1; //Current Month
  let year = new Date().getFullYear();
  let current_date = year + '-' + month + '-' + date;
  let stored_lat = await AsyncStorage.getItem('Latitude');
  let stored_lng = await AsyncStorage.getItem('Longitude');

  axios
    .get(base_url + 'users/restaurant/details', {
      params: {
        restaurantId: rest_id,
        hour: hour,
        minute: minute,
        date: date,
        userId: userId,
        longitude: stored_lng,
        latitude: stored_lat,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      restaurant_deatils = response;
      cb(restaurant_deatils);
    })
    .catch(function (error) {
      console.log('error: ', error);
      cb(error);
    });
};

export const getRestaurantMenuList = async (cb: any, rest_id: any) => {
  let restaurant_menu: any = [];
  axios
    .get(base_url + 'users/listRestaurantMenu', {
      params: {
        restaurantId: rest_id,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.data.status === 'success') {
        restaurant_menu = response;
        cb(restaurant_menu);
      }
    })
    .catch(function (error) {
      console.log('error: ', error);
      cb(error);
    });
};

export const getRestaurantSpecialList = async (cb: any, rest_id: any) => {
  let restaurant_special: any = [];
  axios
    .get(base_url + 'users/listRestaurantMenu', {
      params: {
        restaurantId: rest_id,
        specialItem: 1,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.data.status === 'success') {
        restaurant_special = response;
        cb(restaurant_special);
      }
    })
    .catch(function (error) {
      console.log('error: ', error);
      cb(error);
    });
};


export const getSearchRestaurantList = async (cb: any, term: any, lat: any, lng: any) => {
  let restaurant_search_list: any = [];
  axios
    .get(base_url + 'user/restaurant/search', {
      params: {
        term: term,
        longitude: lng,
        latitude: lat,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.status === 200) {
        restaurant_search_list = response.data.data;
        cb(restaurant_search_list);
      }
    })
    .catch(function (error) {
      console.log('error: ', error);
      cb(error);
    });
};

export const getSearchItem = async (cb: any, term: any, lat: any, lng: any) => {
  let restaurant_search_list: any = [];
  axios
    .get(base_url + 'users/restaurant/item/search', {
      params: {
        term: term,
        longitude: lng,
        latitude: lat,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.status === 200) {
        restaurant_search_list = response;
        cb(restaurant_search_list);
      }
    })
    .catch(function (error) {
      console.log('error: ', error);
      cb(error);
    });
};

export const getItemListResponse = (cb: any, rest_id: any) => {
  axios
    .get(base_url + 'users/restaurant/menu', {
      params: {
        restaurantId: rest_id,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.status === 200) {
        cb(response.data.data);
      }
    })
    .catch(function (error) {
      console.log('error: ', error);
      cb(error);
    });
};

export const postRestuarantView = (cb: any, rest_id: any, userId: any) => {
  axios
    .post(
      base_url + 'users/restaurant/views',
      {
        restaurantId: rest_id,
        userId: userId,
        device: 'app',
        deviceType: Platform.OS === 'android' ? 'android' : 'ios',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
    .then(response => {
      cb(response);
    })
    .catch(error => {
      console.log('error', error);
    });
}

export const specificItemSearch = (cb: any, term: any, rest_id: any) => {
  let restaurant_search_list: any = [];
  axios
    .get(base_url + 'users/restaurant/item/searchMenu', {
      params: {
        itemName: term,
        restaurantId: rest_id,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.status === 200) {
        restaurant_search_list = response;
        cb(restaurant_search_list);
      }
    })
    .catch(function (error) {
      console.log('error: ', error);
      cb(error);
    });
}

export const postWaveBearer = (cb: any, table_id: any, restId: any) => {
  axios
    .post(
      base_url + 'users/restaurant/waveBearer',
      {
        tableId: table_id,
        restaurantId: restId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
    .then(response => {
      cb(response);
    })
    .catch(error => {
      console.log('error', error);
    });
}