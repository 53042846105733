import * as React from "react"
const MailSvg = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#04A39B"
      d="M6.228 13.024c-2.76 0-5 2.23-5 5s2.24 5 5 5 5-2.24 5-5-2.23-5-5-5Zm-2.04 2.96h2.04c.38 0 .68.31.68.68 0 .37-.31.68-.68.68h-2.04a.68.68 0 0 1 0-1.36Zm4.08 4.08h-4.09a.68.68 0 0 1-.68-.68c0-.37.31-.68.68-.68h4.09a.68.68 0 0 1 0 1.36Z"
    />
    <path
      fill="#04A39B"
      d="M17.228 3.024h-10c-3 0-5 1.5-5 5v3.14c0 .73.75 1.19 1.42.9a6.51 6.51 0 0 1 3.66-.45c2.62.43 4.76 2.5 5.29 5.1.15.76.17 1.5.07 2.21-.08.59.4 1.11.99 1.11h3.57c3 0 5-1.5 5-5v-7c0-3.51-2-5.01-5-5.01Z"
      opacity={0.4}
    />
    <path
      fill="#04A39B"
      d="M12.228 11.894c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 0 1 .93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79Z"
    />
  </svg>
)
export default MailSvg
