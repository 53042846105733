import {
    Image,
    ImageBackground,
    Linking,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    useWindowDimensions,
    Modal,
    ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SaveSvg from "./assets/SAVE";
import CallSVG from "./assets/Call";
import MailSvg from "./assets/Mail";
import FooterBlack from "./FooterBlack";
import TwitterSVG from "./assets/socialicons/twitter";
import GOOGLEREVIEWSVG from "./assets/socialicons/GoogleReview";
import {
    ChatOnWhatsapp,
    Facebook,
    Instagram,
    Linkdin,
} from "./assets/socialicons/ICONS";
import TIKTOKSVG from "./assets/Tiktok";
import YOUTUBESVG from "./assets/Youtube";
import GOOGLEMAPSVG from "./assets/socialicons/GoogleMap";
import EATSSVG from "./assets/socialicons/Eats";
import { web_environment } from "../../api/api";
import ArrowRight from "../../components/SVG/ArrowRight";

type Props = {};

const RegisterCard = (props: Props) => {
    const [Details, setDetails] = useState<any>();
    const [loader, setLoader] = useState(true);
    const [showPdf, setSetShowPdf] = useState(false)
    const [showQr, setSetShowQr] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 1500);
        return () => { };
    }, []);

    const { height, width } = useWindowDimensions();

    const renderIcon = (name: string) => {
        if (name === "Eats") {
            return <EATSSVG style={{ marginRight: 8 }} />;
        }
        if (name === "Instagram") {
            return <Instagram style={{ marginRight: 8 }} />;
        }
        if (name === "Facebook") {
            return <Facebook style={{ marginRight: 8 }} />;
        }
        if (name === "Linkedin") {
            return <Linkdin style={{ marginRight: 8 }} />;
        }
        if (name === "X") {
            return <TwitterSVG style={{ marginRight: 8 }} />;
        }
        if (name === "Google Review") {
            return <GOOGLEREVIEWSVG style={{ marginRight: 8 }} />;
        }
        if (name === "Tik Tok") {
            return <TIKTOKSVG style={{ marginRight: 8 }} />;
        }
        if (name === "Youtube") {
            return <YOUTUBESVG style={{ marginRight: 8 }} />;
        }
        if (name === "Google Map") {
            return <GOOGLEMAPSVG style={{ marginRight: 8 }} />;
        }
    };

    if (loader) {
        return (
            <View
                style={{
                    backgroundColor: "#fff",
                    marginTop: 10,
                    flex: 1,
                    paddingVertical: 20,
                    marginHorizontal: 18,
                    borderRadius: 12,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <View style={{ height: 300, width: 300 }}>
                    <Image source={require('../../../assets/lottie/hello.gif')} style={{ height: 200, width: 300 }} />
                </View>
            </View>
        );
    }

    return (

        <View style={styles.container}>
            <View style={{ backgroundColor: "#FF3E55" }}>
                <View>
                    <ImageBackground source={require('../../../assets/digital/Pattern.png')} style={{
                        borderBottomLeftRadius: 18,
                        borderBottomRightRadius: 18,
                        width: width,
                        height: 200,
                    }}
                        resizeMethod="resize"
                    >
                        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center' }}>
                            <View style={{ flexDirection: 'row', width: '100%', flex: 1 }}>
                                {/* <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 20, flex: 0.5 }} /> */}
                                <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 40, flex: 3.5, paddingLeft: 50 }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontSize: 20, fontWeight: '700', color: '#fff' }}>Say Hello 👋</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingRight: 10, paddingTop: 5 }}>
                                        <Text style={{ fontSize: 20, fontWeight: '700', color: '#fff', paddingRight: 10 }}>to Your Digital Card </Text>
                                        <Image
                                            source={require('../../../assets/digital/Vertical.png')}
                                            style={{ width: 16.59, height: 23.67, backgroundColor: '#FF3E55' }}
                                        />
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingTop: 10 }}>
                                        <Text style={{ fontSize: 16, fontWeight: '300', color: '#fff' }}>One Tap to Share Everything</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'column', flex: 2 }}>
                                    <Image
                                        source={require('../../../assets/digital/phone.png')}
                                        style={{ width: 127.4, height: 200, right: 30, position: "absolute", }}
                                        resizeMode="cover"
                                    />
                                </View>
                            </View>
                        </View>
                    </ImageBackground>
                </View>
            </View>
            <View style={{ justifyContent: 'center', backgroundColor: '#fff', paddingTop: 20, paddingBottom: 30, paddingLeft: 20 }}>
                <View style={{ flexDirection: 'row', width: '100%', flex: 1 }}>
                    <View style={{ flexDirection: 'column', justifyContent: 'center', paddingTop: 40, flex: 4, paddingLeft: 10, }}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontSize: 20, fontWeight: '700', color: '#000', fontFamily: "Poppins-Regular", }}>Level Up Your Introductions</Text>
                        </View>
                        <View style={{ flexDirection: 'row', paddingRight: 10, paddingTop: 5 }}>
                            <Text style={{ fontSize: 14, fontWeight: '300', color: '#3A3A3A', paddingRight: 10, fontFamily: "Poppins-Regular", }}>Your contact info, your company's story,</Text>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <Text style={{ fontSize: 14, fontWeight: '300', color: '#3A3A3A', fontFamily: "Poppins-Regular", }}>and your social media spotlight – all</Text>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <Text style={{ fontSize: 14, fontWeight: '300', color: '#3A3A3A', fontFamily: "Poppins-Regular", }}>packed into one sleek digital card.</Text>
                        </View>
                        <View style={{
                            flexDirection: 'row', paddingTop: 15,
                            alignItems: 'center',
                        }}>
                            <TouchableOpacity
                                onPress={() => Linking.openURL('https://mechanfo-digital-card-11a93.web.app/register')}
                                style={[styles.boxWithShadow, {
                                    backgroundColor: '#FF2D46',
                                    flexDirection: 'row',
                                    borderRadius: 86.35,
                                    paddingLeft: 37,
                                    paddingRight: 33,
                                    paddingTop: 18,
                                    paddingBottom: 23,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }]}
                            >
                                <Text style={{ fontSize: 16, fontWeight: '700', color: '#fff', paddingRight: 10, }}>{'Get Started '}</Text>
                                <ArrowRight />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'column', flex: 0.8, alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <Image
                            source={require('../../../assets/digital/Mask.png')}
                            style={{ width: 66.71, height: 142.93, }}
                            resizeMode="cover"
                        />
                    </View>
                </View>
            </View>
            {/* <FooterBlack /> */}

        </View >
    );
};

export default RegisterCard;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#F4F5FA",
    },
    textNameStyle: {
        fontSize: 20,
        fontFamily: "Poppins-SemiBold",
        textAlign: "center",
        marginTop: 40,
        color: "#3A3A3A",
    },
    textStyle: {
        fontSize: 12,
        fontFamily: "Poppins-Regular",
        textAlign: "center",
        marginTop: 10,
        color: "#989EB1",
        width: "90%",
    },
    textBtnStyle: {
        fontSize: 15,
        fontFamily: "Poppins-SemiBold",
        textAlign: "center",
        color: "#ED1D24",
        marginLeft: 10,
    },
    boxWithShadow: {
        shadowColor: '#FF2D46',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
        shadowRadius: 1,
        elevation: 5
    }
});
