import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, ActivityIndicator, Image } from "react-native";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";

const windowWidth = Dimensions.get('window').width;

export const AdOnComponent = () => {
    return (
        <View style={[styles.secondContainer]}>
            {/* <Image source={require('../../../assets/cart/adOn.png')} style={{ width: windowWidth - 50, height: 138.6 }} resizeMode={'contain'} /> */}
            <Text>hhhhh</Text>
        </View>
    );
};

export const AdOnPaynowComponent = ({ payNowButtonPress, cartPayNowButton, tableNo }: any) => {
    const isFocused = useIsFocused();
    const [loaders, setLoaders] = useState(false);
    useEffect(() => {
        setLoaders(false);
    }, [isFocused]);

    return (
        <View style={[styles.secondContainer, { marginTop: 10, backgroundColor: 'transparent' }]}>
            <View style={[styles.payNowButton]}>
                <View style={[styles.payNowContainer]}>
                    <Text style={[styles.textContainer]}>Have you finished</Text>
                    <Text style={[styles.textContainer]}>your meal?</Text>
                </View>
                <TouchableOpacity style={[styles.payNowContainer, styles.buttonConatiner]}
                    onPress={() => {
                        if (tableNo == 0) {
                            setLoaders(false);
                        } else {
                            payNowButtonPress();
                            setLoaders(true);
                        }
                        cartPayNowButton(1);
                    }}>
                    {loaders ? (
                        <ActivityIndicator size="large" color="#fff" />
                    ) : (
                        <Text style={[styles.buttonText]}>Pay Now</Text>
                    )}
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    secondContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 18,
    },
    payNowButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: 18,
        backgroundColor: '#fff',
    },
    payNowContainer: {
        flexDirection: 'column',
        width: '48%',
        justifyContent: 'center',
        padding: 15,
    },
    textContainer: {
        fontSize: 14,
        fontWeight: '600',
        color: 'rgba(58, 58, 58, 1)',
    },
    buttonText: {
        fontSize: 20,
        fontWeight: '600',
        color: '#fff',
    },
    buttonConatiner: {
        backgroundColor: 'rgba(237, 29, 36, 1)',
        borderRadius: 18,
        margin: 10,
        width: '35%',
        alignItems: 'center',
    },
});
