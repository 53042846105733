/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  Modal,
  Alert,
  Text,
  Pressable,
  StyleSheet,
  Dimensions,
  Animated,
} from 'react-native';
//@ts-ignore
// import MLOGO from '../../assets/general/m.svg';
import Lottie from 'lottie-react-native';
import { MECHANFO_BG, MECHANFO_GREEN, MECHANFO_RED, MECHANFO_TEXT_COLOR } from '../../screens/import';
//@ts-ignore
// import EMPTY from '../../assets/general/empty.svg';
//@ts-ignore
// import CARTEMPTY from '../../assets/cart/emptyCart.svg';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export const ModalRedeem = ({ isVisible, onPress }: any) => {
  const [redeemText, setRedeemText] = useState('Pending For Approval');
  const [redeemStatus, setRedeemStatus] = useState(1);
  const animationRef = useRef<Lottie>(null);
  const animationRef_A = useRef<Lottie>(null);

  useEffect(() => {
    ApiCall();
    animationRef.current?.play();

    // Or set a specific startFrame and endFrame with:
    animationRef.current?.play(30, 120);
  }, []);

  const ApiCall = () => {
    setTimeout(() => {
      setRedeemText('Approve');
      setRedeemStatus(3);
      animationRef_A.current?.play();
      animationRef_A.current?.play(30, 120);
    }, 3000);
  };

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isVisible}
        onRequestClose={() => {
          // Alert.alert('Modal has been closed.');
          // setRedeemIsVisible(!redeemIsVisible);
        }}>
        <View style={[styles.centeredView]}>
          <View style={[styles.modalView]}>
            <View
              style={[
                styles.logoMainContainer,
                { marginTop: -35, backgroundColor: '#fff' },
              ]}>
              <View style={[styles.logoContainer]}>
                {/* <MLOGO width={44} height={44} /> */}
              </View>
            </View>
            <View
              style={{
                marginTop: 5.33,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 22.04,
              }}>
              <Text style={[styles.textStyle, { fontSize: 20 }]}>
                Redeem Coupon
              </Text>

              <View
                style={{
                  height: 100,
                  width: 163.26,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {redeemStatus === 1 || redeemStatus === 2 ? (
                  // <Lottie
                  //   ref={animationRef}
                  //   source={require('../../assets/gif/redeem.json')}
                  //   style={{ height: 163.26, width: 163.26 }}
                  // />
                  <Text>dfjkjdf</Text>
                ) : (
                  // <Lottie
                  //   ref={animationRef_A}
                  //   source={require('../../assets/gif/approve.json')}
                  //   style={{ height: 200, width: 200 }}
                  // />
                  <Text>qqqqq</Text>
                )}
              </View>

              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={[styles.textStyle, { fontSize: 16 }]}>
                  MID ME00006
                </Text>
                <Text
                  style={[styles.textStyle, { fontSize: 12, marginTop: 10.37 }]}>
                  CODE#KHNJ12344
                </Text>
                {redeemStatus === 1 ? (
                  <Text
                    style={[
                      styles.textStyle,
                      { fontSize: 16, marginTop: 17.18, color: '#A1A1A6' },
                    ]}>
                    {redeemText}
                  </Text>
                ) : redeemStatus === 2 ? (
                  <Text
                    style={[
                      styles.textStyle,
                      {
                        fontSize: 20,
                        marginTop: 17.18,
                        color: MECHANFO_RED,
                      },
                    ]}>
                    {redeemText}
                  </Text>
                ) : redeemStatus === 3 ? (
                  <Text
                    style={[
                      styles.textStyle,
                      {
                        fontSize: 20,
                        marginTop: 17.18,
                        color: MECHANFO_GREEN,
                      },
                    ]}>
                    {redeemText}
                  </Text>
                ) : null}
                <Pressable style={[]} onPress={() => onPress(false)}>
                  <Text>Hide Modal</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export const Empty = (text1: any, text2: any) => {
  return (
    <View style={[styles.secondContainer]}>
      {/* <EMPTY width={147.57} height={117.94} /> */}
      {/* <Text
        style={{
          fontSize: 20,
          fontWeight: '500',
          color: '#000',
          textAlign: 'center',
          marginBottom: 19.76,
        }}>
        Comming Soon
      </Text> */}
      <Text
        style={{
          textAlign: 'center',
          color: '#3A3A3A',
          fontSize: 20,
          fontWeight: '500',
        }}>
        You haven`t done any
      </Text>
      <Text
        style={{
          textAlign: 'center',
          color: '#3A3A3A',
          fontSize: 20,
          fontWeight: '500',
        }}>
        Transactions
      </Text>
    </View>
  );
};

export const CartEmpty = (text1: any, text2: any) => {
  return (
    <View style={{}}>
      {/* <CARTEMPTY width={147.57} height={150.8} marginBottom={40} /> */}
      <Text
        style={{
          fontSize: 20,
          fontWeight: '500',
          color: '#000',
          textAlign: 'center',
          marginBottom: 17,
        }}>
        Your Cart is empty
      </Text>
      <Text
        style={{
          textAlign: 'center',
          color: '#989EB1',
          fontSize: 14,
          fontWeight: '400',
        }}>
        Looks like you haven`t added
      </Text>
      <Text
        style={{
          textAlign: 'center',
          color: '#989EB1',
          fontSize: 14,
          fontWeight: '400',
        }}>
        anything to cart yet
      </Text>
    </View>
  );
};

export const CommonEmpty = ({ text1, text2 }: any) => {
  return (
    <View style={[styles.secondContainer]}>
      {/* <EMPTY width={147.57} height={117.94} /> */}
      <Text
        style={[styles.textStyleCommon]}>
        {text1}
      </Text>
      <Text style={[styles.textStyleCommon]}>
        {text2}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: 22,
    backgroundColor: 'rgba(193,199,208,0.5)',
  },
  modalView: {
    // margin: 20,
    backgroundColor: 'white',
    borderRadius: 18,
    padding: 5,
    width: windowWidth - 50,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  logoMainContainer: {
    backgroundColor: '#fff',
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 70 / 2,
  },
  logoContainer: {
    backgroundColor: MECHANFO_BG,
    width: 57.86,
    height: 57.86,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 57.86 / 2,
  },
  textStyle: {
    fontWeight: '500',
    color: MECHANFO_TEXT_COLOR,
  },
  secondContainer: {
    backgroundColor: MECHANFO_BG,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStyleCommon: {
    textAlign: 'center',
    color: '#989EB1',
    fontSize: 14,
    fontWeight: '500',
  }
});
