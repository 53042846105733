import * as React from "react"
const YOUTUBESVG = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={31}
        height={31}
        viewBox="0 0 48 48"
        {...props}
    >
        <path
            fill="#FF3D00"
            d="M43.2 33.9c-.4 2.1-2.1 3.7-4.2 4-3.3.5-8.8 1.1-15 1.1-6.1 0-11.6-.6-15-1.1-2.1-.3-3.8-1.9-4.2-4-.4-2.3-.8-5.7-.8-9.9s.4-7.6.8-9.9c.4-2.1 2.1-3.7 4.2-4C12.3 9.6 17.8 9 24 9c6.2 0 11.6.6 15 1.1 2.1.3 3.8 1.9 4.2 4 .4 2.3.9 5.7.9 9.9-.1 4.2-.5 7.6-.9 9.9z"
        />
        <path fill="#FFF" d="M20 31V17l12 7z" />
    </svg>
)
export default YOUTUBESVG