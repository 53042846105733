import {
    Image,
    ImageBackground,
    Linking,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    useWindowDimensions,
    Modal,
    ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SaveSvg from "./assets/SAVE";
import CallSVG from "./assets/Call";
import MailSvg from "./assets/Mail";
import FooterBlack from "./FooterBlack";
import TwitterSVG from "./assets/socialicons/twitter";
import GOOGLEREVIEWSVG from "./assets/socialicons/GoogleReview";
import {
    ChatOnWhatsapp,
    Facebook,
    Instagram,
    Linkdin,
} from "./assets/socialicons/ICONS";
import TIKTOKSVG from "./assets/Tiktok";
import YOUTUBESVG from "./assets/Youtube";
import GOOGLEMAPSVG from "./assets/socialicons/GoogleMap";
import EATSSVG from "./assets/socialicons/Eats";
import { web_environment } from "../../api/api";
import RIGHTSVG from "./assets/RightArrow";

type Props = {};

const OfferList = ({ route, navigation }: any) => {
    const [Details, setDetails] = useState<any>();
    const [loader, setLoader] = useState(true);
    const [showPdf, setSetShowPdf] = useState(false);
    const [showQr, setSetShowQr] = useState(false);
    const [offerArray, setOfferArray] = useState([]);

    useEffect(() => {
        getDetails();
        return () => { };
    }, []);

    const getDetails = async () => {
        try {
            if (route.params.list) {
                setOfferArray(route.params.list);
            }
            setTimeout(() => {
                setLoader(false);
            }, 1500);
        } catch (error) {
            console.log(error);
        }
    };

    const { height, width } = useWindowDimensions();

    if (loader) {
        return (
            <View
                style={{
                    backgroundColor: "#fff",
                    marginTop: 10,
                    flex: 1,
                    paddingVertical: 20,
                    marginHorizontal: 18,
                    borderRadius: 12,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <View style={{ height: 300, width: 300 }}>
                    <Image source={require('../../../assets/lottie/hello.gif')} style={{ height: 200, width: 300 }} />
                </View>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <View style={{ justifyContent: 'center', backgroundColor: '#fff', paddingTop: 20, paddingBottom: 30, paddingLeft: 20, paddingRight: 20 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image source={require('../../../assets/general/left_arrow.png')} style={{ width: 15, height: 15 }} resizeMode="contain" />
                    </TouchableOpacity>
                    <Text style={{ color: '#121212', fontWeight: '600', fontSize: 16, fontFamily: "Poppins-Regular" }}>⚡ Partner Offers for you </Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 20 }}>
                    <ScrollView
                        horizontal={false}
                        showsVerticalScrollIndicator={false}
                    >
                        {offerArray.map((offers: any) => {
                            return (
                                <View key={offers._id} style={{ paddingBottom: 16 }}>
                                    <View style={{ backgroundColor: '#F4F5FA' }}>
                                        <Image source={offers.image} style={{ height: 256.25, width: '100%' }} />
                                        <View style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 20, flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                                            <View style={{ flexDirection: 'column', flex: 3 }}>
                                                <Text style={{ color: '#121212', fontSize: 18, fontWeight: '500' }}>{offers.title}</Text>
                                                <Text style={{ color: '#807E7E', fontSize: 12, fontWeight: '400', paddingTop: 10 }}>{offers.description}</Text>
                                            </View>
                                            <View style={{ flexDirection: 'column', flex: 2, alignItems: 'flex-end', paddingRight: 10 }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        Linking.openURL(offers.link);
                                                    }}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        paddingLeft: 20,
                                                        paddingRight: 20,
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                    }}
                                                >
                                                    <Text style={{ color: '#121212', fontSize: 15, fontWeight: '500' }}>Get Offer</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>
            </View>
            <FooterBlack />

        </View>
    );
};

export default OfferList;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#F4F5FA",
    },
    textNameStyle: {
        fontSize: 20,
        fontFamily: "Poppins-SemiBold",
        textAlign: "center",
        marginTop: 40,
        color: "#3A3A3A",
    },
    textStyle: {
        fontSize: 12,
        fontFamily: "Poppins-Regular",
        textAlign: "center",
        marginTop: 10,
        color: "#989EB1",
        width: "90%",
    },
    textBtnStyle: {
        fontSize: 15,
        fontFamily: "Poppins-SemiBold",
        textAlign: "center",
        color: "#ED1D24",
        marginLeft: 10,
    },
    boxWithShadow: {
        shadowColor: '#FF2D46',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
        shadowRadius: 1,
        elevation: 5
    }
});
