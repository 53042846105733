import { Image, Linking, StyleSheet, Text, View } from 'react-native';
import React, { useEffect } from 'react';
import { MECHANFO_FONT_REGULAR, MECHANFO_GRAY, MECHANFO_RED, MECHANFO_TEXT_COLOR } from '../import';
import { TouchableOpacity } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { OpenAppRestaurantPage } from '../util';
import { useAppSelector } from '../../components/store/reduxHooks';
import { Cart } from '../KOT/cartSlice';
import ItemButton from '../../components/Restaurant/ItemButton';

const PItemView = ({
  item_id,
  image,
  price,
  item_name,
  country,
  kotEnabled,
  status,
  reservationsEnabled,
  tableOrderEnabled,
  takeAwayEnabled,
  checkAddItem,
  handleAddItemToCart,
  item,
  navigation,
  token,
  rest_id,
  maxDistance,
  distance,
}: any) => {

  const { cartItemData, cart_restaurant, activeDineInOrder } =
    useAppSelector(Cart);
  useEffect(() => {
  }, [navigation]);

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingHorizontal: 16,
        backgroundColor: '#fff',
        paddingVertical: 8,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <TouchableOpacity
        disabled={item.variantExists ? false : true}
        onPress={() => {
          handleAddItemToCart(item);
        }}
        style={{ flexDirection: 'row', width: '71%', }}>
        <View>
          {image && (
            <Image
              source={{ uri: image }}
              resizeMode={'contain'}
              style={[
                {
                  height: 68,
                  width: 77,
                  borderRadius: 18,
                  paddingRight: 10,
                  backgroundColor: '#EBEAEA',
                },
              ]}
            />
          )}
        </View>
        <View style={{ marginLeft: 8 }}>
          <Text
            style={[
              styles.itemTextStyle,
              {
                fontSize: 14,
                color: '#3A3A3A',
                fontFamily: 'Poppins-SemiBold',
                marginTop: 5,
              },
            ]}>
            {item_name}{' '}
          </Text>
          <Text
            style={[
              {
                fontSize: 12,
                color: '#989EB1',
                marginVertical: 2,
                // fontFamily:"Poppins-Regular"
              },
            ]}>
            {item_name}{' '}
          </Text>
          <Text
            style={{
              fontSize: 16,
              color: '#3a3a3a',
              fontFamily: 'Poppins-SemiBold',
            }}>
            {country}
            {price}{' '}
          </Text>
        </View>
      </TouchableOpacity>
      <View style={{ flexDirection: 'row', width: '30%', justifyContent: 'flex-end' }}>
        <View style={{ flexDirection: 'column' }}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 10, }}>
            <TouchableOpacity
              onPress={() => {
                OpenAppRestaurantPage(rest_id)
                // console.log("agent is:", window?.navigator?.userAgent)

                // alert("platform is: ", window?.navigator?.platform) 

                // console.log("user agent data: ", window?.navigator?.userAgentData)
                // if (token) {
                //   navigation.navigate(
                //     'RateRestaurant',
                //     {
                //       rest_id:
                //         rest_id,
                //       item_id: item.item_id,
                //       userRating:
                //         item?.userRating,
                //       userComment:
                //         item?.userComment,
                //       maxDistance:
                //         maxDistance,
                //       userDistance:
                //         distance,
                //       restDetail: null,
                //     },
                //   );
                // } else {
                //   navigation.navigate('LoginHomeStack', { screen: 'Login' });
                // }
              }}>
              <AnimatedCircularProgress
                size={40}
                width={3}
                fill={item.rating * 20}
                tintColor={MECHANFO_RED}
                rotation={360}
                backgroundColor={
                  MECHANFO_GRAY
                }>
                {fill => (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent:
                        'center',
                      alignItems: 'center',
                    }}>
                    <Image
                      source={require('../../../assets/general/m.png')}
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Text
                      style={{
                        color: MECHANFO_RED,
                        fontSize: 12,
                        fontFamily:
                          MECHANFO_FONT_REGULAR,
                        marginTop: -10,
                        fontWeight: '500',
                        marginLeft: -1,
                      }}>
                      {item.rating}
                    </Text>
                  </View>
                )}
              </AnimatedCircularProgress>
            </TouchableOpacity>
          </View>
          {/* <TouchableOpacity style={{ flexDirection: 'row' }}>
            {kotEnabled &&
              status &&
              (takeAwayEnabled == true ||
                reservationsEnabled == true ||
                tableOrderEnabled == true) ? (
              cartItemData &&
                cartItemData[item_id] &&
                cartItemData[item_id].quantity ? (
                <ItemButton
                  handleDecrement={() => {
                    checkAddItem(item, 0);
                  }}
                  handleIncrement={() => {
                    checkAddItem(item, 1);
                  }}
                  count={
                    cartItemData[item_id] && cartItemData[item_id].quantity
                      ? cartItemData[item_id].quantity
                      : 0
                  }
                />
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    handleAddItemToCart(item);
                  }}
                  style={{
                    backgroundColor: '#FFE6E6',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#FFA7A7',
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    height: 35,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={{
                      color: '#ED1D24',
                      fontSize: 16,
                      fontWeight: '600',
                    }}>
                    ADD
                  </Text>
                </TouchableOpacity>
              )
            ) : (
              <View
                // disabled={item.variantExists ? false : true}
                // onPress={() => {
                //   handleAddItemToCart(item);
                // }}
                style={{
                  backgroundColor: '#FFE6E6',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#FFE6E6',
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  height: 35,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text
                  style={{
                    color: '#FFA7A7',
                    fontSize: 16,
                    fontWeight: '600',
                  }}>
                  ADD
                </Text>
              </View>
            )}
          </TouchableOpacity> */}
        </View>
      </View>
    </View>
  );
};

export default PItemView;

const styles = StyleSheet.create({
  itemTextStyle: {
    fontFamily: MECHANFO_FONT_REGULAR,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#6E7070',
  },
});
