import React from "react";
import { Image, Linking, Pressable, Text, View } from "react-native";
import AppStore from "../../components/SVG/AppStore";
import PlayStore from "../../components/SVG/PlayStore";

type Props = {};

const Footer = (props: Props) => {
  return (
    <>
      <View
        style={{
          backgroundColor: "#fff",
          marginTop: 12,
          paddingHorizontal: 12,
          paddingTop: 20,
        }}
      >
        <View
          style={{
            marginHorizontal: -12,
            backgroundColor: "#ED1D24",
            height: 4,
            width: "110%",
            position: "absolute",
            top: 0,
          }}
        ></View>
        <Text
          style={{
            marginLeft: 8,
            marginBottom: 0,
            color: "#424141",
            fontFamily: "Poppins-Semibold",
          }}
        >
          Powered By
        </Text>
        <Image
          source={require("../../../assets/restuarant/logo.png")}
          style={[{ height: 80, width: 200 }]}
          resizeMode="contain"
        />
      </View>
      <View
        style={{
          backgroundColor: "#ED1D24",
          paddingHorizontal: 12,
          paddingVertical: 8,
        }}
      >
        <Text
          style={{
            marginBottom: 8,
            color: "#fff",
            fontFamily: "Poppins-Semibold",
          }}
        >
          Get the app from{" "}
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Pressable
            onPress={() => {
              Linking.openURL(
                "https://play.google.com/store/apps/details?id=com.mechanfo.pro.food"
              );
            }}
          >
            <PlayStore />
          </Pressable>

          <Pressable
            style={{ marginLeft: 8 }}
            onPress={() => {
              Linking.openURL(
                "https://apps.apple.com/in/app/mechanfo-eats/id6444594193"
              );
            }}
          >
            <AppStore />
          </Pressable>
        </View>

        <Text
          style={{
            marginBottom: 8,
            marginTop: 12,
            color: "#fff",
            fontFamily: "Poppins-Regular",
          }}
        >
          ©2023 Mechanfo Eats | All Rights Reserved
        </Text>
      </View>
    </>
  );
};

export default Footer;
