import { FlatList, Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import React, { useState } from "react";
import { ShadowedView } from "react-native-fast-shadow";
import { useItemsStore } from "../store/items";
import PListItem from "./PureComponents/PListItem";
import PItemView from "./PureComponents/PItemView";
import { MECHANFO_FONT_REGULAR, MECHANFO_RED } from "./import";
import Footer from "./PureComponents/Footer";
import ArrowBack from "../components/SVG/ArrowBack";

type Props = {};

const SearchItemScreen = (props: any) => {
  
  const items = useItemsStore((state) => state.items);
  const restDetail = useItemsStore((state) => state.restDetail);

  const [SearchedItems, setSearchedItems] = useState([]);

  function searchItemsByName(nameToSearch: any) {
    const results: any = [];

    items.forEach((category: any) => {
      const matchingItems = category.itemList.filter((item: any) =>
        item.item_name.toLowerCase().includes(nameToSearch.toLowerCase())
      );

      if (
        matchingItems.length > 0 ||
        category.categoryName.toLowerCase().includes(nameToSearch.toLowerCase())
      ) {
        results.push({
          ...category,
          itemList: matchingItems,
        });
      }
    });

    if (nameToSearch) {
      setSearchedItems(results);
    } else {
      setSearchedItems([]);
    }
  }

  return (
    <View style={styles.container}>
      <ShadowedView
        style={{
          shadowOpacity: 0.2,
          shadowRadius: 6,
          shadowOffset: {
            width: 3,
            height: 2,
          },
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#fff",
            paddingVertical: 12,
            paddingHorizontal:8,
            elevation: 6,
            shadowOpacity: 1,
          }}
        >
          <Pressable onPress={()=>{props.navigation.goBack()}}>
              <ArrowBack />
          </Pressable>
          <TextInput
            style={{
              backgroundColor: "#fff",
              marginHorizontal: 6,
              borderRadius: 12,
              padding: 15,
              borderWidth: 1,
              borderColor: "rgba(0, 0, 0, 0.06)",
              flex: 1,
              //@ts-ignore
              outlineWidth: 0,
            }}
            onChangeText={(text) => searchItemsByName(text)}
            placeholder="Search items"
            placeholderTextColor={"rgba(0, 0, 0, 0.6)"}
          />
        </View>
      </ShadowedView>
      <FlatList
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 0 }}
        data={SearchedItems}
        renderItem={({ item: category }: any) => {
          return (
            <View key={category.categoryName}>
              <PListItem
                cat_id={category.cat_id}
                onPress={() => {}}
                categoryName={category.categoryName}
                count={category.itemList ? category.itemList.length : 0}
                isExpanded={true}
              />
              {true && (
                <FlatList
                  showsVerticalScrollIndicator={false}
                  data={category.itemList}
                  // estimatedItemSize={83.4}
                  ItemSeparatorComponent={() => (
                    <View style={{ height: 5, backgroundColor: "#F4F5FA" }} />
                  )}
                  renderItem={({ item }: any) => {
                    return (
                      <PItemView
                        key={item.item_id}
                        country={props.route.params && props.route.params.country ? props.route.params.country : ""}
                        image={item.image}
                        item_name={item.item_name}
                        price={item.price}
                        kotEnabled={false}
                        item_id={item.item_id}
                        reservationsEnabled={restDetail?.reservationsEnabled}
                        status={item.status}
                        tableOrderEnabled={restDetail?.tableOrderEnabled}
                        takeAwayEnabled={restDetail?.takeAwayEnabled}
                        // checkAddItem={checkAddItem}
                        item={item}
                        // handleAddItemToCart={handleAddItemToCart}
                        // token={userToken}
                        rest_id={restDetail?._id}
                        maxDistance={restDetail?.maxDistance}
                        distance={restDetail?.distance}
                      />
                    );
                  }}
                />
              )}
            </View>
          );
        }}
        keyExtractor={(item: any) => item.cat_id}
        // getItemLayout={getItemLayout}
        initialNumToRender={8}
        removeClippedSubviews={true}
        maxToRenderPerBatch={8}
        windowSize={10}
        ListEmptyComponent={() => (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginTop: 10,
            }}
          >
           
            {/* <Lottie
                    source={require('../../assets/lottie/empty-menu.json')}
                    style={{ height: 400, width: 400 }}
                    autoPlay
                  /> */}
          </View>
        )}
      />
    </View>
  );
};

export default SearchItemScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
