/* eslint-disable prettier/prettier */
import { addToCart, clearCart } from './cartSlice';
// import { reserveTable } from './reversationTableSlice';

export const handleChangeVariantItemQuantity = (
  cartItemData: any,
  dispatch: any,
  item: any,
  variant: any,
  value: any,
) => {
  // console.log('SSSS', item, variant, value);
  if (value == 0) {
    if (cartItemData[item.item_id]['variant'][variant._id].quantity == 1) {
      // remove item
      let vdata = { ...cartItemData[item.item_id]['variant'] };
      delete vdata[variant._id];
      let cartData = {
        ...cartItemData,
        [item.item_id]: {
          ...cartItemData[item.item_id],
          quantity: cartItemData[item.item_id].quantity - 1,
          variant: vdata,
        },
      };
      if (Object.values(cartData[item.item_id]['variant']).length == 0) {
        // console.log("Delete Item");
        delete cartData[item.item_id];
        if (Object.values(cartData).length == 0) {
          dispatch(clearCart());
          // dispatch(
          //   reserveTable({
          //     data: {},
          //   }),
          // );
        } else {
          dispatch(addToCart({ cartItems: cartData, restaurant: false }));
        }
      } else {
        // remove item from cart
        dispatch(addToCart({ cartItems: cartData, restaurant: false }));
      }
    } else {
      let cartData = {
        ...cartItemData,
        [item.item_id]: {
          ...cartItemData[item.item_id],
          quantity: cartItemData[item.item_id].quantity - 1,
          variant: {
            ...cartItemData[item.item_id]['variant'],
            [variant._id]: {
              ...cartItemData[item.item_id]['variant'][variant._id],
              quantity:
                cartItemData[item.item_id]['variant'][variant._id].quantity - 1,
            },
          },
        },
      };
      dispatch(addToCart({ cartItems: cartData, restaurant: false }));
    }
  } else {
    let cartData = {
      ...cartItemData,
      [item.item_id]: {
        ...cartItemData[item.item_id],
        quantity: cartItemData[item.item_id].quantity + 1,
        variant: {
          ...cartItemData[item.item_id]['variant'],
          [variant._id]: {
            ...cartItemData[item.item_id]['variant'][variant._id],
            quantity:
              cartItemData[item.item_id]['variant'][variant._id].quantity + 1,
          },
        },
      },
    };
    dispatch(addToCart({ cartItems: cartData, restaurant: false }));
  }
};

export const handleChangeItemQuantity = (
  cartItemData: any,
  dispatch: any,
  item: any,
  value: any,
) => {
  if (value == 0) {
    if (cartItemData[item.item_id].quantity == 1) {
      // remove item
      let cartData = { ...cartItemData };
      delete cartData[item.item_id];
      if (Object.values(cartData).length == 0) {
        // console.log("CLEAR CART");
        dispatch(clearCart());
        // dispatch(
        //   reserveTable({
        //     data: {},
        //   }),
        // );
      } else {
        // remove item from cart
        dispatch(addToCart({ cartItems: cartData, restaurant: false }));
      }
    } else {
      let cartData = {
        ...cartItemData,
        [item.item_id]: {
          ...cartItemData[item.item_id],
          quantity: cartItemData[item.item_id].quantity - 1,
        },
      };
      dispatch(addToCart({ cartItems: cartData, restaurant: false }));
    }
  } else {
    let cartData = {
      ...cartItemData,
      [item.item_id]: {
        ...cartItemData[item.item_id],
        quantity: cartItemData[item.item_id].quantity + 1,
      },
    };
    dispatch(addToCart({ cartItems: cartData, restaurant: false }));
  }
};
