import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const SvgComponent = (props: SvgProps) => (
  <Svg
  //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    fill="none"
    {...props}
  >
    <Path
      stroke="#505050"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.94 7.148a5.835 5.835 0 0 1-5.834 5.834 5.835 5.835 0 0 1-5.833-5.834 5.835 5.835 0 0 1 5.833-5.833 5.835 5.835 0 0 1 5.834 5.833Z"
    />
    <Path
      stroke="#505050"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.27 9.003 7.462 7.924c-.315-.186-.572-.636-.572-1.003V4.529"
    />
  </Svg>
)
export default SvgComponent
