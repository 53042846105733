import * as React from "react"
const CallSVG = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#0098FF"
      d="M17.62 10.774a.77.77 0 0 1-.77-.77c0-.37-.37-1.14-.99-1.81-.61-.65-1.28-1.03-1.84-1.03a.77.77 0 0 1-.77-.77c0-.42.35-.77.77-.77 1 0 2.05.54 2.97 1.51.86.91 1.41 2.04 1.41 2.86 0 .43-.35.78-.78.78ZM21.23 10.774a.77.77 0 0 1-.77-.77c0-3.55-2.89-6.43-6.43-6.43a.77.77 0 0 1-.77-.77c0-.42.34-.78.76-.78 4.4 0 7.98 3.58 7.98 7.98 0 .42-.35.77-.77.77Z"
    />
    <path
      fill="#0098FF"
      d="m11.79 14.234-3.27 3.27c-.36-.32-.71-.65-1.05-.99a28.414 28.414 0 0 1-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41-.48-1.14-.72-2.23-.72-3.27 0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67.64-.63 1.34-.94 2.08-.94.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.36.35.71.69 1.06.99Z"
      opacity={0.4}
    />
    <path
      fill="#0098FF"
      d="M21.97 18.354c0 .28-.05.57-.15.85-.03.08-.06.16-.1.24-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.01 0-.02.01-.03.01-.59.24-1.23.37-1.92.37-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98c-.39-.29-.78-.58-1.15-.89l3.27-3.27c.28.21.53.37.74.48.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78Z"
    />
  </svg>
)
export default CallSVG
