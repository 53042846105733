import React from "react";
import { FlatList, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import PDFIcon from "../PDFView/PDFIcon";

export const SectionComponent = ({ sectionList, changeActiveSection, activeSection, openPdf, pdfView }: any) => {

    const renderSectionList = ({ item }: any) => {
        return (
            <TouchableOpacity
                style={[styles.sectionContainer, { backgroundColor: activeSection == item._id ? 'rgba(229, 242, 255, 1)' : '#fff' }]}
                onPress={() => changeActiveSection(item._id)}
            >
                <Text style={[styles.mainTextStyle]}>{item.title}</Text>
            </TouchableOpacity>
        );
    };

    return (
        <View style={{ margin: 15, marginTop: 25, flexDirection: 'row', alignItems: 'center' }}>
            <FlatList
                data={sectionList}
                showsHorizontalScrollIndicator={false}
                renderItem={renderSectionList}
                horizontal={true}
            />
            {/* <TouchableOpacity onPress={() => { openPdf(true) }}>
                {pdfView ?
                    <PDFIcon />
                    : null}
            </TouchableOpacity> */}
        </View>
    );
};

const styles = StyleSheet.create({
    sectionContainer: {
        backgroundColor: '#fff',
        // padding: 15,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 13,
        paddingBottom: 13,
        marginRight: 10,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: '#cce6ff',
    },
    mainTextStyle: {
        color: '#000',
        fontSize: 12,
        fontWeight: '500',
    }
});