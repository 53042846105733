/* eslint-disable prettier/prettier */
export const calculateCartTotal = cart => {
  let cartTotal = 0;
  if (Object.values(cart).length == 0) {
    return cartTotal;
  }

  let cartItems = Object.values(cart);

  cartItems.forEach((item: any) => {
    let itemTotal = 0;
    if (item.variantExists) {
      itemTotal = calculateVariantItemTotal(item.variant);
    } else {
      itemTotal = Number(item.price) * item.quantity;
    }
    cartTotal = cartTotal + itemTotal;
  });
  return cartTotal;
};

const calculateVariantItemTotal = variant => {
  let variantTotal = 0;
  let variantItems = Object.values(variant);

  if (variantItems.length == 0) {
    return 0;
  }

  variantItems.forEach((item: any) => {
    let itemTotal = 0;
    itemTotal = Number(item.price) * item.quantity;
    variantTotal = variantTotal + itemTotal;
  });

  return variantTotal;
};

export const CartItemCount = cart => {
  let cartItemsCount = 0;
  if (Object.values(cart).length == 0) {
    return cartItemsCount;
  }

  let cartItems = Object.values(cart);
  cartItems.forEach((item: any) => {
    cartItemsCount = cartItemsCount + item.quantity;
  });
  return cartItemsCount;
};
