import * as React from "react"
import Svg, { Defs, ClipPath, Path, Mask, G, Use } from "react-native-svg"
const EATSSVG = (props: any) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={31}
        height={31}
        {...props}
    >
        <Defs>
            <ClipPath id="b">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="c">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="d">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="e">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="f">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="g">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="h">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="i">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="j">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="k">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="l">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="m">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="n">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <ClipPath id="o">
                <path d="M0 0h31v31H0z" />
            </ClipPath>
            <Mask id="q">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.00784314,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="s">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="u">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="w">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.00784314,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="y">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.180392,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="A">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.184314,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="C">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="E">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="G">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="I">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="K">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="M">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="O">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.0117647,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <Mask id="Q">
                <G filter="url(#a)">
                    <path
                        d="M0 0h31v31H0z"
                        style={{
                            fill: "#000",
                            fillOpacity: 0.00784314,
                            stroke: "none",
                        }}
                    />
                </G>
            </Mask>
            <G id="p" clipPath="url(#b)">
                <path
                    d="M4.441 2.988c7.29-.187 14.61-.215 21.957-.082-7.32.082-14.64.11-21.957.082Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="r" clipPath="url(#c)">
                <path
                    d="M3.957 3.148c.063-.164.172-.191.32-.082a.382.382 0 0 1-.32.082Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="t" clipPath="url(#d)">
                <path
                    d="M26.559 3.148c.066-.164.171-.191.324-.082a.39.39 0 0 1-.324.082Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="v" clipPath="url(#e)">
                <path
                    d="M27.852 4.441c.214 7.317.214 14.637 0 21.957V4.441Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="x" clipPath="url(#f)">
                <path
                    d="M3.473 3.957c0 .106-.055.16-.164.16-.215.055-.215.11 0 .16V26.56c-.215.054-.215.109 0 .164.109 0 .164.05.164.16-.262.207-.422.125-.485-.242.215-7.48.215-14.961 0-22.442.063-.37.223-.449.485-.242Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#290505",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="z" clipPath="url(#g)">
                <path
                    d="M27.852 4.441a374.083 374.083 0 0 0 0 21.957c.043.305-.063.52-.325.645 0-.105-.05-.16-.16-.16 0-.11.055-.16.16-.16.215-.055.215-.11 0-.164V4.277c.215-.05.215-.105 0-.16-.105 0-.16-.054-.16-.16.38-.16.543 0 .485.484Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#2d0504",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="B" clipPath="url(#h)">
                <path
                    d="M27.367 27.043h.16c-.027.11-.082.11-.16 0Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="D" clipPath="url(#i)">
                <path
                    d="M27.207 27.207h.16c-.031.105-.082.105-.16 0Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="F" clipPath="url(#j)">
                <path
                    d="M27.043 27.367h.164c-.031.11-.086.11-.164 0Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="H" clipPath="url(#k)">
                <path
                    d="M3.957 27.527v.164c-.11-.078-.11-.132 0-.164Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="J" clipPath="url(#l)">
                <path
                    d="M26.883 27.527h.16c-.027.11-.082.11-.16 0Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="L" clipPath="url(#m)">
                <path
                    d="M3.957 27.691a.38.38 0 0 1 .32.079c-.148.113-.257.085-.32-.079Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="N" clipPath="url(#n)">
                <path
                    d="M26.883 27.691c-.063.164-.172.192-.324.079a.388.388 0 0 1 .324-.079Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
            <G id="P" clipPath="url(#o)">
                <path
                    d="M4.441 28.012c7.29-.188 14.61-.215 21.957-.078-7.32.078-14.64.105-21.957.078Zm0 0"
                    style={{
                        stroke: "none",
                        fillRule: "evenodd",
                        fill: "#000",
                        fillOpacity: 1,
                    }}
                />
            </G>
        </Defs>
        <Use xlinkHref="#p" mask="url(#q)" />
        <Use xlinkHref="#r" mask="url(#s)" />
        <Use xlinkHref="#t" mask="url(#u)" />
        <path
            d="M27.367 3.957c0 .106.055.16.16.16v22.606c-.105 0-.16.05-.16.16v.16c-.11 0-.16.055-.16.164-.11 0-.164.05-.164.16-.105 0-.16.055-.16.16-7.64.215-15.285.215-22.926 0-.164-.215-.324-.43-.484-.644 0-.11-.055-.16-.164-.16V4.117c.109 0 .164-.054.164-.16.203-.394.527-.637.968-.727 7.317-.109 14.637-.109 21.957 0 .442.09.762.333.97.727Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#ea1d24",
                fillOpacity: 1,
            }}
        />
        <Use xlinkHref="#v" mask="url(#w)" />
        <Use xlinkHref="#x" mask="url(#y)" />
        <Use xlinkHref="#z" mask="url(#A)" />
        <path
            d="M19.617 14.129h-1.613c-.05.73 0 1.43.16 2.098.012.308.172.496.484.566.426.016.829.043 1.211.078.403.406.32.649-.242.727-1.242.27-2.074-.188-2.504-1.371-.078-.7-.105-1.399-.078-2.098h-.648v-.809h.484v-1.293h1.293v1.133c.602-.055 1.195 0 1.777.16l-.324.809Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#fef5f5",
                fillOpacity: 1,
            }}
        />
        <path
            d="M7.668 13.16c1.469-.25 2.52.29 3.148 1.613.125.368.098.715-.078 1.051-1.09.172-2.168.39-3.23.645.703.715 1.43.742 2.18.082.546.539.414.89-.403 1.047-1.281.37-2.223-.035-2.828-1.211-.457-1.399-.055-2.473 1.211-3.227Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#fff3f3",
                fillOpacity: 1,
            }}
        />
        <path
            d="M21.879 13.16a4.17 4.17 0 0 1 2.098.32c-.11.215-.27.38-.485.485a1.985 1.985 0 0 0-1.293-.078c-.215.215-.215.43 0 .644.914.16 1.696.563 2.344 1.211.129.895-.219 1.512-1.05 1.856-.946.215-1.805.054-2.583-.485.18-.172.34-.36.485-.562.726.484 1.37.433 1.937-.164a.643.643 0 0 0-.324-.563 5.475 5.475 0 0 1-1.695-1.05c-.231-.715-.04-1.25.566-1.614Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#fff1f1",
                fillOpacity: 1,
            }}
        />
        <path
            d="M12.836 13.16c1.094-.168 1.926.207 2.504 1.13.144 1.124.223 2.257.242 3.39a1.79 1.79 0 0 1-1.375-.325c-.883.493-1.691.383-2.422-.32-.55-1.426-.066-2.234 1.453-2.422.367-.011.688.098.969.32.102-.386-.031-.683-.402-.886-.586-.156-1.121-.074-1.614.242a1.88 1.88 0 0 1-.324-.402c.266-.352.59-.594.969-.727Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#fff3f3",
                fillOpacity: 1,
            }}
        />
        <path
            d="M19.617 14.129c-.449.156-.933.21-1.453.16v1.938-1.938c.52.05 1.004-.004 1.453-.16Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#f25659",
                fillOpacity: 1,
            }}
        />
        <path
            d="M7.992 14.29c.434-.028.863 0 1.293.081.29.102.504.29.645.563-.91.304-1.825.574-2.746.808a2.027 2.027 0 0 1 .808-1.453Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#ee2a2b",
                fillOpacity: 1,
            }}
        />
        <path
            d="M12.996 15.418c1.14-.129 1.516.328 1.133 1.375-.793.41-1.332.195-1.617-.648.133-.266.293-.508.484-.727Zm0 0"
            style={{
                stroke: "none",
                fillRule: "evenodd",
                fill: "#ee2c2c",
                fillOpacity: 1,
            }}
        />
        <Use xlinkHref="#B" mask="url(#C)" />
        <Use xlinkHref="#D" mask="url(#E)" />
        <Use xlinkHref="#F" mask="url(#G)" />
        <Use xlinkHref="#H" mask="url(#I)" />
        <Use xlinkHref="#J" mask="url(#K)" />
        <Use xlinkHref="#L" mask="url(#M)" />
        <Use xlinkHref="#N" mask="url(#O)" />
        <Use xlinkHref="#P" mask="url(#Q)" />
    </Svg>
)
export default EATSSVG