import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const ArrowBack = (props: SvgProps) => (
  <Svg
   //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M13.124 8.538 5.031 16.63l8.093 8.094M27.697 16.631H5.257"
    />
  </Svg>
)
export default ArrowBack
