import React, { useEffect } from "react";
import { ImageBackground, View, Text, StyleSheet, TouchableOpacity, Image } from "react-native";
import { MECHANFO_BUTTON, MECHANFO_FONT_REGULAR, MECHANFO_GRAY, MECHANFO_PLACEHOLDERCOLOR, MECHANFO_RED } from "../import";
//@ts-ignore

//@ts-ignore

import ArrowBackSVG from "../../components/SVG/ArrowBackSVG";
import BackSVG from "../../components/SVG/BackSVG";
import ReportSVG from "../../components/SVG/ReportSVG";

export const HeaderDetailComponent = ({ bgImage, logo, restDetail, couponPress, reservationPress, openMapFunction, wavePress, reportAnIssue, rateRestaurant, tableNoExist, goBack }: any) => {
    return (
        <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <ImageBackground
                imageStyle={{ borderBottomLeftRadius: 18, borderBottomRightRadius: 18 }}
                source={{ uri: bgImage }}
                style={[styles.backgroundImageStyle]}>
                <View style={[styles.overlay]}>
                    <View style={[styles.whiteBox]}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                            }}>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    width: '20%',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <View style={[styles.iconContainer,]}>
                                    <Image
                                        source={{ uri: logo }}
                                        style={{
                                            width: 58.79,
                                            height: 58.79,
                                            borderRadius: 58.79 / 2,
                                        }}
                                        resizeMode={"contain"}
                                    />
                                </View>
                            </View>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    // width: '50%',
                                    flex: 1,
                                    justifyContent: 'center',
                                    paddingLeft: 2,
                                }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={[styles.textNameStyle]}>{restDetail?.displayName}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', }}>
                                    <Text style={[styles.textCityStyle]}>{restDetail?.city} </Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    // width: '18%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 8,
                                    marginEnd: 12,
                                }}>
                                {/* <TouchableOpacity
                                    onPress={rateRestaurant} style={{ flexDirection: 'row', backgroundColor: 'rgba(255, 231, 231, 1)', padding: 10, borderRadius: 10 }}>
                                    <Image
                                        source={require('../../../assets/general/m.png')}
                                        style={[{ height: 20, width: 18 }]}
                                    />
                                    <Text
                                        style={{
                                            color: MECHANFO_RED,
                                            fontSize: 12,
                                            fontWeight: '500',
                                            marginTop: -5,
                                        }}>
                                        {restDetail?.rating}
                                    </Text>
                                </TouchableOpacity> */}
                                <View style={{ flexDirection: 'row', paddingTop: 5, justifyContent: 'center', alignItems: 'center' }}>
                                    <Image
                                        source={require('../../../assets/restuarant/eye.png')}
                                        style={[{ height: 20, width: 15 }]}
                                        resizeMode="contain"
                                    />
                                    <Text
                                        style={{
                                            color: '#000',
                                            fontSize: 12,
                                            fontWeight: '500',
                                            marginLeft: 5,
                                            marginTop: 1
                                        }}>
                                        {restDetail?.totalViews}
                                    </Text>
                                </View>
                                {/* <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={[styles.textStyle,]}>
                                        GST Applicable
                                    </Text>
                                </View> */}

                            </View>
                        </View>
                        <View
                            style={[
                                {
                                    flexDirection: 'row',
                                    borderRadius: 8,
                                    width: '98%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: 5,
                                    paddingLeft: 3
                                },
                            ]}>
                            <View style={{ flexDirection: 'column', width: '50%' }}>
                                {restDetail?.type == 'Veg' ? (
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Image
                                            source={require('../../../assets/restuarant/veg.png')}
                                            style={{ width: 11, height: 11 }}
                                        />
                                        <Text style={[styles.typeStyle]}>Veg.</Text>
                                    </View>
                                ) :
                                    restDetail?.type == 'Non Veg' ? (
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <Image
                                                source={require('../../../assets/restuarant/Nonveg.png')}
                                                style={{ width: 11, height: 11 }}
                                            />
                                            <Text
                                                style={[
                                                    styles.typeStyle,
                                                ]}>
                                                Non Veg.
                                            </Text>
                                        </View>
                                    ) : restDetail?.type == 'Both' ? (
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <Image
                                                source={require('../../../assets/restuarant/veg.png')}
                                                style={{ width: 11, height: 11 }}
                                                resizeMode='cover'
                                            />
                                            <Text style={[styles.typeStyle]}>Veg</Text>
                                            <Image
                                                source={require('../../../assets/restuarant/Nonveg.png')}
                                                style={{
                                                    width: 11,
                                                    height: 11,
                                                    marginLeft: 5,
                                                }}
                                                resizeMode='cover'
                                            />
                                            <Text style={[styles.typeStyle]}>Non Veg.</Text>
                                        </View>
                                    ) : null}
                            </View>
                            <View style={{
                                flexDirection: 'column',
                                width: '50%',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                            }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <BackSVG width={14} height={14} />
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            paddingLeft: 5,
                                            paddingTop: 2,
                                        }}>
                                        {/* </View> */}
                                        {!restDetail?.is24 ? (
                                            <>
                                                <View style={{ flexDirection: 'column' }}>
                                                    {restDetail?.startTime != '' &&
                                                        restDetail?.endTime != '' && (
                                                            <Text style={[styles.timeStyle]}>
                                                                {restDetail?.startTime} -
                                                            </Text>
                                                        )}
                                                </View>
                                                <View style={{ flexDirection: 'column' }}>
                                                    {restDetail?.startTime != '' &&
                                                        restDetail?.endTime != '' && (
                                                            <Text style={[styles.timeStyle]}>
                                                                {restDetail?.endTime}
                                                            </Text>
                                                        )}
                                                </View>
                                            </>
                                        ) : (
                                            <View style={{ flexDirection: 'column' }}>
                                                <Text style={[styles.timeStyle]}>24 hours</Text>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </View>
                        </View>
                        {/* </View>
                    <View style={[styles.whiteBox, { marginTop: 10 }]}> */}
                        <View style={{ backgroundColor: MECHANFO_GRAY, height: 1, width: '100%', marginTop: 10 }} />
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                flexWrap: 'wrap',
                                // backgroundColor: 'rgba(229, 242, 255, 1)',
                                borderRadius: 8,
                                marginTop: 10,
                            }}>
                            <TouchableOpacity onPress={couponPress}
                                style={{
                                    flexDirection: 'column',
                                    backgroundColor: 'rgba(255, 231, 231, 1)',
                                    // backgroundColor: 'rgba(229, 242, 255, 1)',
                                    // borderRadius: 8,
                                    // paddingLeft: 10,
                                    // paddingRight: 10,
                                    borderRadius: 12,
                                    borderWidth: 1,
                                    borderColor: 'rgba(255, 231, 231, 1)',
                                    marginRight: 10,
                                }}>
                                <View style={{
                                    flexDirection: 'row', justifyContent: 'center',
                                    alignItems: 'center', padding: 7,
                                }}>
                                    <Image
                                        source={require('../../../assets/profile/ticket-star.png')}
                                        style={[{ height: 30, width: 30 }]}
                                    />
                                    {/* <Text style={{ color: 'rgba(68, 68, 68, 1)', fontSize: 14, fontWeight: '600', }}> Coupons</Text> */}
                                </View>
                            </TouchableOpacity>
                            {restDetail ?
                                <TouchableOpacity
                                    disabled={restDetail.reservationOnlyEnabled == true ? false : true}
                                    onPress={reservationPress}
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: restDetail.reservationOnlyEnabled == true ? 'rgba(255, 231, 231, 1)' : '#fff',
                                        // borderRadius: 8,
                                        // paddingLeft: 10,
                                        // paddingRight: 10,
                                        borderRadius: 12,
                                        borderWidth: 1,
                                        borderColor: 'rgba(255, 231, 231, 1)',
                                        // padding: 10,
                                        marginRight: 10,
                                    }}>
                                    <View style={{
                                        flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center',
                                        alignItems: 'center', padding: 7,
                                    }}>
                                        <Image
                                            source={require('../../../assets/restuarant/dinner-table.png')}
                                            style={[{ height: 30, width: 30 }]}
                                        />
                                    </View>
                                </TouchableOpacity> : null}
                            <TouchableOpacity
                                onPress={openMapFunction}
                                style={{
                                    flexDirection: 'column',
                                    // backgroundColor: 'rgba(229, 242, 255, 1)',
                                    // borderRadius: 8,
                                    // paddingLeft: 10,
                                    // paddingRight: 10,
                                    // width: '40%',
                                    // borderRadius: 18,
                                    // borderWidth: 1,
                                    backgroundColor: 'rgba(255, 231, 231, 1)',
                                    borderRadius: 12,
                                    borderWidth: 1,
                                    borderColor: 'rgba(255, 231, 231, 1)',
                                    marginRight: 10,
                                }}>
                                <View style={{
                                    flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center',
                                    alignItems: 'center', padding: 7,
                                }}>
                                    <Image
                                        source={require('../../../assets/restuarant/locationMap.png')}
                                        style={[{ height: 30, width: 30 }]}
                                    />
                                    {/* <LOCATION width={16} height={16} /> */}
                                    {/* <Text style={{ color: 'rgba(68, 68, 68, 1)', fontSize: 14, fontWeight: '600', }}>Direction</Text> */}
                                </View>
                            </TouchableOpacity>
                            { }
                            <TouchableOpacity
                                onPress={rateRestaurant}
                                style={{
                                    flexDirection: 'row',
                                    backgroundColor: 'rgba(255, 231, 231, 1)',
                                    padding: 10,
                                    borderRadius: 10,
                                    marginRight: 10,
                                }}>
                                <Image
                                    source={require('../../../assets/general/m.png')}
                                    style={[{ height: 25, width: 20 }]}
                                />
                                <Text
                                    style={{
                                        color: MECHANFO_RED,
                                        fontSize: 12,
                                        fontWeight: '500',
                                        marginTop: -5,
                                    }}>
                                    {restDetail?.rating}
                                </Text>
                            </TouchableOpacity>
                            {tableNoExist ?
                                <TouchableOpacity onPress={wavePress}
                                    style={{
                                        flexDirection: 'column',
                                        backgroundColor: 'rgba(255, 231, 231, 1)',
                                        // backgroundColor: 'rgba(229, 242, 255, 1)',
                                        // borderRadius: 8,
                                        // paddingLeft: 10,
                                        // paddingRight: 10,
                                        borderRadius: 12,
                                        borderWidth: 1,
                                        borderColor: 'rgba(255, 231, 231, 1)',
                                        marginRight: 10,
                                    }}>
                                    <View style={{
                                        flexDirection: 'row', justifyContent: 'center',
                                        alignItems: 'center', padding: 7,
                                    }}>
                                        <Image
                                            source={require('../../../assets/restuarant/wave.png')}
                                            style={[{ height: 30, width: 30 }]}
                                        />
                                        {/* <Text style={{ color: 'rgba(68, 68, 68, 1)', fontSize: 14, fontWeight: '600', }}> Coupons</Text> */}
                                    </View>
                                </TouchableOpacity>
                                : null}
                        </View>
                    </View>
                </View>
            </ImageBackground>
            {/* <TouchableOpacity onPress={goBack} style={{ position: 'absolute', top: 35, left: 10 }}>
                <ArrowBackSVG />
            </TouchableOpacity>
            <TouchableOpacity onPress={reportAnIssue} style={{ position: 'absolute', top: 35, right: 10 }}>
                <ReportSVG />
            </TouchableOpacity> */}
        </View>
    );
}

const styles = StyleSheet.create({
    overlay: {
        // height: '100%',
        // flexDirection: 'row',
        marginTop: 12,
        width: '90%',
    },
    iconContainer: {
        // marginTop: 60,
        backgroundColor: '#fff',
        width: 58.79,
        borderRadius: 58.79 / 2,
        height: 58.79,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        flexDirection: 'column',
    },
    textStyle: {
        fontFamily: MECHANFO_FONT_REGULAR,
        fontSize: 10,
        fontWeight: '300',
        color: MECHANFO_PLACEHOLDERCOLOR,
    },
    backgroundImageStyle: {
        height: 310,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 20,
        borderRadius: 20,
    },
    textNameStyle: {
        color: 'rgba(0, 0, 0, 1)',
        fontWeight: '600',
        fontSize: 20,
    },
    textCityStyle: {
        color: 'rgba(152, 158, 177, 1)',
        fontWeight: '400',
        fontSize: 12,
    },
    typeStyle: {
        marginLeft: 5,
        fontWeight: '400',
        color: '#000',
        fontSize: 12,
        fontFamily: MECHANFO_FONT_REGULAR,
        marginTop: 2,
    },
    timeStyle: {
        fontSize: 12,
        color: 'rgba(80, 80, 80, 1)',
        fontWeight: '400',
        fontFamily: MECHANFO_FONT_REGULAR,
        // marginTop: 2,
    },
    whiteBox: {
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: 18,
        padding: 5,
        paddingTop: 20,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
    },
})