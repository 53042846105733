import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { base_url } from '../../api/api';

export const getAllOrderHistoryList = async ({ cb }: any) => {
  let all_order_list: any = [];
  const token = await AsyncStorage.getItem('token');
  if (token) {
    axios
      .get(base_url + 'users/order/allOrders', {
        params: {
          countryId: null,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async response => {
        if (response.data) {
          all_order_list = response.data;
          cb(all_order_list);
        }
      })
      .catch(function (error) {
        console.log('error: ', error);
        cb(error);
      });
  }
  cb(all_order_list);
};

export const cancelOrderRequest = async (cb: any, orderId: any) => {
  let user_cancel_order: any = [];
  const token = await AsyncStorage.getItem('token');
  if (token) {
    axios
      .post(
        base_url + 'users/order/cancel',
        {
          orderId: orderId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        cb(response);
      })
      .catch(error => {
        console.log('error', error);
        cb(user_cancel_order);
      });
  }
};

export const getOrderHistoryList = async (cb: any) => {
  const token = await AsyncStorage.getItem('token');
  if (token) {
    axios
      .get(base_url + 'users/order/allOrders', {
        params: {
          countryId: null,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async response => {
        cb(response);
      })
      .catch(function (error) {
        console.log('error: ', error);
      });
  }
};

export const createOrder = async (cb: any, cart_data: any) => {
  const token = await AsyncStorage.getItem('token');
  if (token) {
    axios
      .post(base_url + 'users/order/create', cart_data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        cb(response);
      })
      .catch(err => {
        cb(err);
      });
  }
};

export const updateOrder = async (cb: any, cart_data: any) => {
  const token = await AsyncStorage.getItem('token');
  if (token) {
    axios
      .post(base_url + 'users/order/updateOrder', cart_data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        cb(response);
      })
      .catch(err => {
        cb(err);
      });
  }
};

export const paymentAfterFood = async (cb: any, cart_data: any, id: any) => {
  const token = await AsyncStorage.getItem('token');
  if (token) {
    axios
      .post(base_url + 'users/order/paymentAfterFood', cart_data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        cb(response);
      })
      .catch(err => {
        cb(err);
      });
  }
};