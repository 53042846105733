import axios from "axios";
import moment from "moment";
const PRODUCTION = true;

export const base_url = PRODUCTION
  ? 'https://api.mechanfo.com/'
  :
  'https://mechanfo-development.el.r.appspot.com/';

const DIGITAL_PRODUCTION = true;

export const base_url_digital = DIGITAL_PRODUCTION
  // ? 'https://mechanfo-digital-frontend.el.r.appspot.com/'
  ? 'https://mechanfo-digital-card-prod.el.r.appspot.com/'
  // ? 'http://127.0.0.1:8080/'
  : 'https://mechanfo-digitalcard-dev.el.r.appspot.com/';
// : 'http://127.0.0.1:8080/';

export const web_environment = PRODUCTION;


export const getRestaurantdetailsData = async (
  cb: any,
  rest_id: any,
  userId: any,
) => {

  let restaurant_deatils: any = [];
  let hour = new Date().getHours();
  let minute = new Date().getMinutes();
  let date = new Date().getDate(); //Current Date
  let month = new Date().getMonth() + 1; //Current Month
  let year = new Date().getFullYear();
  let current_date = year + '-' + month + '-' + date;
  // let stored_lat = await AsyncStorage.getItem('Latitude');
  // let stored_lng = await AsyncStorage.getItem('Longitude');

  axios
    .get(base_url + 'users/restaurant/details', {
      params: {
        restaurantId: rest_id,
        hour: hour,
        minute: minute,
        date: date,
        userId: userId,
        //   longitude: stored_lng,
        //   latitude: stored_lat,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async (response: any) => {
      restaurant_deatils = response;
      cb(restaurant_deatils);
    })
    .catch(function (error: any) {
      // console.log('error: ', error);
      cb(error);
    });
};


export const getRestaurantMenuList = async (cb: any, rest_id: any) => {
  let restaurant_menu: any = [];

  axios
    .get(base_url + 'users/listRestaurantMenu', {
      params: {
        restaurantId: rest_id,
        day: moment().weekday(),
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.data.status === 'success') {
        restaurant_menu = response;
        cb(restaurant_menu);
      }
    })
    .catch(function (error) {
      // console.log('error: ', error);
      cb(error);
    });
};

export const checkNewUser = async (cb: any, unique_id: any) => {
  let new_user_response: any = [];

  axios
    .post(
      base_url_digital + 'users/checkUniqueId',
      {
        uniqueId: unique_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
    .then(response => {
      cb(response.data);
    })
    .catch(error => {
      console.log('error', error);
    });
};

export const getOfferList = async (cb: any, country_id: any) => {
  let offer_list: any = [];

  axios
    .get(base_url_digital + 'users/digitalCard/listAdvertisementUser', {
      params: {
        countryId: country_id,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.data.status === 'success') {
        offer_list = response.data;
        cb(offer_list);
      }
    })
    .catch(function (error) {
      // console.log('error: ', error);
      cb(error);
    });
};

export const getExportContact = async (cb: any, uniqueId: any) => {
  let offer_list: any = [];

  axios
    .get(base_url_digital + 'users/digitalCard/listAdvertisementUser', {
      params: {
        uniqueId: uniqueId,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(async response => {
      if (response.data.status === 'success') {
        offer_list = response.data;
        cb(offer_list);
      }
    })
    .catch(function (error) {
      // console.log('error: ', error);
      cb(error);
    });
};