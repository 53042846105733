/* eslint-disable prettier/prettier */
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

type Props = {
  count: number;
  handleIncrement: () => any;
  handleDecrement: () => any;
};

const ItemButton = (props: Props) => {
  return (
    <View style={styles.button}>
      <TouchableOpacity
        onPress={props.handleDecrement}
        style={styles.buttonSmall}>
        <Text style={styles.font}>-</Text>
      </TouchableOpacity>
      <Text style={[styles.font, { fontSize: 16 }]}>{props.count}</Text>
      <TouchableOpacity
        onPress={props.handleIncrement}
        style={styles.buttonSmall}>
        <Text style={styles.font}>+</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    backgroundColor: '#FFE6E6',
    borderWidth: 1,
    borderColor: '#FFA7A7',
    width: 80,
    height: 38,
    borderRadius: 9,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonSmall: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: 32,
    width: 25,
  },
  font: { fontFamily: 'Poppins-SemiBold', fontSize: 18, color: '#ED1D24' },
});

export default ItemButton;
