import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
  Animated,
  Linking,
  Platform,
  TextInput,
  ScrollView,
  Image,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react-native";
import {
  MECHANFO_FONT_REGULAR,
  MECHANFO_RED,
  MECHANFO_TEXT_COLOR,
} from "./import";

import PListItem from "./PureComponents/PListItem";
import PItemView from "./PureComponents/PItemView";

//@ts-ignore
import BottomSheet from "react-native-gesture-bottom-sheet";
import VariantSheet from "./PureComponents/VariantSheet";
//@ts-ignore
import ArrowBack from "../../assets/restuarant/arrow-back.svg";
import { SectionComponent } from "./PureComponents/SectionComponent";
import axios from "axios";
import { IRestaurant } from "./types/Restaurant";
import { getRestaurantMenuList, getRestaurantdetailsData, web_environment } from "../api/api";
import { HeaderDetailComponent } from "./PureComponents/HeaderDetail";
import Footer from "./PureComponents/Footer";
import { ShadowedView } from "react-native-fast-shadow";
import { useItemsStore } from "../store/items";
import { OpenAppRestaurantPage } from "./util";
import { useSelector } from "react-redux";
import { addToCart, Cart, clearCart } from "./KOT/cartSlice";
import { useAppDispatch } from "../components/store/reduxHooks";
import { CartItemCount, calculateCartTotal } from '../components/Restaurant/utils/price';
import { handleChangeItemQuantity } from '../screens/KOT/CartUtils';

//@ts-ignore
import Modal from 'react-native-modalbox';
import PdfView from "./PDFView/PdfView";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { postWaveBearer } from "../components/request/restaurant";
//@ts-ignore
import Video from 'react-native-video';
//@ts-ignore
import video from '../../assets/lottie/eats.mov'

const RestuarnatDetail = ({ navigation }: any) => {
  const [expandedCategories, setExpandedCategories] = useState<any>({});
  const [categoryList, setCategoryList] = useState([] as any[]);
  const [restDetail, setRestDetail] = useState<IRestaurant | null>();
  const [menuLoading, setMenuLoading] = useState(true);
  // should remove
  const [isShowing, setIsShowing] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [menuStatus, setMenuStatus] = useState(false);
  const [variant, setVariant] = useState<any>({});
  const [variantName, setVariantName] = useState();
  const [variantList, setVariantList] = useState<any[]>([]);
  const [itemImage, setItemImage] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [address, setAddress] = useState("");
  const [userToken, setUserToken] = useState<any>();
  const [cgst, setCgst] = useState<any>();
  const [sgst, setSgst] = useState<any>();
  const [reservationCharge, setReservationCharge] = useState<any>();
  const [emptyCartModal, setEmptyCartModal] = useState(false);
  const [kotEnabled, setKotEnabled] = useState(true);
  // bottomsheet ref
  const bottomSheet = useRef<any | null>(null);
  const { height: windowHeight } = useWindowDimensions();
  const [activeOrderModal, setActiveOrderModal] = useState(false);
  //country
  const [country, setCountry] = useState();
  const [sectionList, setSectionList] = useState([]);
  const [activeSection, setActiveSection] = useState();
  const [allcategory, setAllCategory] = useState([]);
  const [sectionLoading, setSectionLoading] = useState(true);
  const [headerLoading, serHeaderLoading] = useState(true);
  const [restId, setrestId] = useState(undefined);
  //redux
  const { cartItemData, cart_restaurant, activeDineInOrder } = useSelector(Cart);
  const dispatch = useAppDispatch();
  const [myArr, setMyArr] = useState<any>([]);
  const [pdfView, setPdfView] = useState<any>();
  const [loadHandWave, setLoadHandWave] = useState(false);
  const [tableNoExist, setTableNoExist] = useState(false);

  const onClose = () => {
    setIsActive(true);
  };

  // to fetch on focus

  const [showPdf, setSetShowPdf] = useState(false)

  useEffect(() => {
    getResId();
    return () => { };
  }, []);

  const queryParams = new URLSearchParams(window.location.search);

  const getResId = async () => {
    // const BASE = "https://mechanfo.com";

    // taking base url from origin 
    const BASE = window.location.origin;

    const res_name = window.location.href.split(BASE + "/restaurant/")[1];
    if (web_environment) {
      const response = await axios.get(
        `https://api.mechanfo.com/users/menu/restaurant/${res_name}`
      );
      setrestId(response.data.restaurant._id);
    } else {
      const response = await axios.get(
        `https://mechanfo-development.el.r.appspot.com/users/menu/restaurant/${res_name}`
      );
      setrestId(response.data.restaurant._id);
    }
    const table_no = queryParams.get('t');
    if (table_no) {
      setTableNoExist(true);
    }
  };

  useEffect(() => {
    fetchRestaurantDetail();
    return () => { };
  }, [restId]);

  const { setItemsToStore, setRestDetailStore } = useItemsStore()

  const getListRestaurantMenu = async (sect_id: any) => {
    if (restId) {
      getRestaurantMenuList((list: any) => {
        if (list) {
          const res_section = queryParams.get('section');

          if (list.data.categories && list.data.categories.length > 0) {
            setMenuLoading(false);
            if (res_section) {
              let item_list = list.data.categories;
              let newData: any = [];
              item_list.map((item: any) => {
                if (item.sectionId == res_section) {
                  newData = [...newData, item]
                }
              });
              setItemsToStore(newData);
            } else {
              setItemsToStore(list.data.categories);
            }
            // setItemsToStore(list.data.categories);
            let temp: any = [];
            if (res_section) {
              list.data.categories.map((cat: any) => {
                if (cat.sectionId == res_section) {
                  let temp_data: any = cat;
                  //@ts-ignore
                  temp.push(temp_data);
                }
              });
              setCategoryList(temp);
              setSectionLoading(false);
              setTimeout(() => {
                serHeaderLoading(false);
              }, 1000);
            } else {
              setSectionLoading(true);
              setCategoryList(list.data.categories);
              setTimeout(() => {
                serHeaderLoading(false);
              }, 1000);
            }
            // setCategoryList(list.data.categories);
            // setTimeout(() => {
            //   serHeaderLoading(false);
            // }, 1000);

            setAllCategory(list.data.categories);
            setTimeout(() => {
              setExpandedCategories({
                [list.data.categories[0].cat_id]: true,
              });
            }, 500);
          } else {
            setSectionLoading(true);
            setMenuLoading(false);
          }
        }
      }, restId);
    }
  };

  const fetchRestaurantDetail = async () => {
    try {
      getRestaurantdetailsData(
        (list: any) => {
          if (list.data.status === "success") {
            // if (list.data.restaurant.pdfMenu) {
            //   setPdfView(list.data.restaurant.pdfMenu);
            // }
            setSectionList(list.data.restaurant.sections);
            if (list.data.restaurant.sections.length > 0) {
              setActiveSection(list.data.restaurant.sections[0]._id);
              setSectionLoading(false);
              getListRestaurantMenu(list.data.restaurant.sections[0]._id);
            } else {
              getListRestaurantMenu(1);
            }
            setRestDetail(list.data.restaurant);
            const removeQuotes = (string: any) => string.replace(/"(.+)"/g, "");
            setCountry(removeQuotes(list.data.restaurant.countryId.symbol));
            //@ts-ignore
            setRestDetail((restDetail) => ({
              ...restDetail,
              currency: removeQuotes(list.data.restaurant.countryId.symbol),
              currencyCode: removeQuotes(
                list.data.restaurant.countryId.currency
              ),
            }));
            setRestDetailStore({
              ...list.data.restaurant,
              currency: removeQuotes(list.data.restaurant.countryId.symbol),
              currencyCode: removeQuotes(
                list.data.restaurant.countryId.currency
              ),
            });
            setKotEnabled(true);
            setCgst(list.data.restaurant.cgst);
            setSgst(list.data.restaurant.sgst);
            setReservationCharge(list.data.restaurant.reservationCharge);
          }
        },
        restId,
        undefined
      );
    } catch (e) {
      // console.log(e);
    }
  };

  const setExpandedView = (cat_id: any) => {
    if (expandedCategories[cat_id]) {
      setExpandedCategories({
        ...expandedCategories,
        [cat_id]: false,
      });
    } else {
      setExpandedCategories({
        ...expandedCategories,
        [cat_id]: true,
      });
    }
  };

  const checkAddItem = (item: any, value: any) => {
    if (item.variantExists) {
      // open variant modal
      bottomSheetHandler(item);
    } else {
      handleChangeItemQuantity(cartItemData, dispatch, item, value);
    }
  };

  const handleAddItemToCart = async (item: any) => {
    if (item.variantExists) {
      // item has variant
      bottomSheetHandler(item);
    } else {
      // check cart restaurant and add res are same,
      if (Object.values(cartItemData).length == 0) {
        if (Object.entries(activeDineInOrder).length != 0) {
          if (//@ts-ignore
            activeDineInOrder.restaurantId._id == restDetail._id &&
            activeDineInOrder.orderStatus != 0
          ) {
            // cart empty add item to cart
            let cartItems = {
              [item.item_id]: {
                item_id: item.item_id,
                item_name: item.item_name,
                description: item.description,
                price: item.price,
                quantity: 1,
                ...item,
              },
            };
            dispatch(addToCart({ cartItems: cartItems, restaurant: restDetail }));
          } else {
            // setActiveOrderModal(true);
          }
        } else {
          // cart empty add item to cart
          let cartItems = {
            [item.item_id]: {
              item_id: item.item_id,
              item_name: item.item_name,
              description: item.description,
              price: item.price,
              quantity: 1,
              ...item,
            },
          };
          dispatch(addToCart({ cartItems: cartItems, restaurant: restDetail }));
        }
      } else {
        if (restDetail && cart_restaurant._id != restDetail._id) {
          // ask to clear cart and add item modal;
          setEmptyCartModal(true);
        } else {
          // add res and item to cart;
          let cartItems = {
            ...cartItemData,
            [item.item_id]: {
              item_id: item.item_id,
              item_name: item.item_name,
              description: item.description,
              price: item.price,
              quantity: 1,
              ...item,
            },
          };
          dispatch(addToCart({ cartItems: cartItems, restaurant: false }));
        }
      }
    }
  };

  const bottomSheetHandler = (item: any) => {
    setIsShowing(true);
    setVariant(item);
    setVariantList(item.variant);
    setVariantName(item.item_name);
    setItemImage(item.image);
    setMenuStatus(item.status);
    bottomSheet.current.show();
  };

  // const couponPress = async () => {
  //   const tokens = await AsyncStorage.getItem('token');
  //   if (tokens) {
  //     navigation.navigate('restCoupon', {
  //       //@ts-ignore
  //       restId: restDetail._id,
  //       //@ts-ignore
  //       restName: restDetail.displayName,
  //       type: 2,
  //     });
  //   } else {
  //     navigation.navigate('LoginHomeStack', { screen: 'Login' });
  //   }
  // };

  // const reservationPress = async () => {
  //   if (tokens) {
  //     navigation.navigate('reservationOnly', {
  //       restId: restDetail?._id,
  //       //@ts-ignore
  //       restaurantName: restDetail.displayName,
  //     });
  //   } else {
  //     navigation.navigate('LoginHomeStack', { screen: 'Login' });
  //   }
  // };

  const wavePress = async () => {
    const tokens = await AsyncStorage.getItem('token');
    const table_no = queryParams.get('t');

    postWaveBearer((list: any) => {
      if (list.data.status == 'success') {
        setLoadHandWave(true);
        setTimeout(() => {
          setLoadHandWave(false);
        }, 1000);
      }
    }, table_no, restId);
  };

  const ITEM_HEIGHT = 65; // fixed height of item component
  const getItemLayout = (data: any, index: any) => {
    return {
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    };
  };

  // scroll animation
  const [scrollY] = useState(new Animated.Value(0));

  const coverTranslateY = scrollY.interpolate({
    inputRange: [-4, 0, 10],
    outputRange: [0, 0, -2],
  });

  const coverScale = scrollY.interpolate({
    inputRange: [-200, 0],
    outputRange: [1, 1],
    //@ts-ignore
    extrapolateRight: "clamp",
  });

  const tabBarOpacity = scrollY.interpolate({
    inputRange: [150, 250],
    outputRange: [0, 1],
    //@ts-ignore
    extrapolate: "clamp",
  });

  const tabbatTraslateY = scrollY.interpolate({
    inputRange: [-4, 0, 10],
    outputRange: [0, -100, 0],
    //@ts-ignore
    extrapolate: "clamp",
  });

  const tabBarOpacityRevers = scrollY.interpolate({
    inputRange: [150, 250],
    outputRange: [1, 0],
    //@ts-ignore
    extrapolate: "clamp",
  });

  const closeActiveOrderModal = (item: Boolean) => {
    setActiveOrderModal(false);
  };

  const sectionBasedMenu = (sect_id: any) => {
    let temp: any = [];
    if (sect_id) {
      allcategory.map((cat: any) => {
        if (cat.sectionId == sect_id) {
          let temp_data: any = cat;
          //@ts-ignore
          temp.push(temp_data);
        }
      });
      setCategoryList(temp);
    }
    setMenuLoading(false);
  };

  const changeActiveSection = (value: any) => {
    setMenuLoading(true);
    setActiveSection(value);
    sectionBasedMenu(value);
  };

  const handleAddVariantToCart = (item: any, variant: any) => {
    if (Object.values(cartItemData).length == 0) {
      if (Object.entries(activeDineInOrder).length != 0) {
        if (
          restDetail &&
          activeDineInOrder.restaurantId._id == restDetail._id
        ) {
          // cart empty add item to cart
          let cartItems = {
            [item.item_id]: {
              item_id: item.item_id,
              item_name: item.item_name,
              description: item.description,
              price: item.price,
              quantity: 1,
              ...item,
              variant: {
                [variant._id]: {
                  ...variant,
                  quantity: 1,
                },
              },
              variantExists: true,
            },
          };
          dispatch(addToCart({ cartItems: cartItems, restaurant: restDetail }));
        } else {
          setActiveOrderModal(true);
        }
      } else {
        // cart empty add item to cart
        let cartItems = {
          [item.item_id]: {
            item_id: item.item_id,
            item_name: item.item_name,
            description: item.description,
            price: item.price,
            quantity: 1,
            ...item,
            variant: {
              [variant._id]: {
                ...variant,
                quantity: 1,
              },
            },
            variantExists: true,
          },
        };
        dispatch(addToCart({ cartItems: cartItems, restaurant: restDetail }));
      }
    } else {
      if (restDetail && cart_restaurant._id != restDetail._id) {
        // ask to clear cart and add item modal;
        setEmptyCartModal(true);
      } else {
        // add res and item to cart;
        let cartItems = {};

        if (cartItemData[item.item_id] && cartItemData[item.item_id].quantity) {
          // already have one item of this variant
          cartItems = {
            ...cartItemData,
            [item.item_id]: {
              item_id: item.item_id,
              item_name: item.item_name,
              description: item.description,
              price: item.price,
              quantity:
                cartItemData[item.item_id] &&
                  cartItemData[item.item_id].quantity
                  ? cartItemData[item.item_id].quantity + 1
                  : 1,
              variant: {
                ...cartItemData[item.item_id]['variant'],
                [variant._id]: {
                  ...variant,
                  quantity: 1,
                },
              },
              variantExists: true,
            },
          };
        } else {
          cartItems = {
            ...cartItemData,
            [item.item_id]: {
              item_id: item.item_id,
              item_name: item.item_name,
              description: item.description,
              price: variant.price,
              quantity: 1,
              variant: {
                [variant._id]: {
                  ...variant,
                  quantity: 1,
                },
              },
              variantExists: true,
            },
          };
        }
        dispatch(addToCart({ cartItems: cartItems, restaurant: false }));
      }
    }
  };

  if (headerLoading) {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          marginTop: 10,
          flex: 1,
          paddingVertical: 20,
          marginHorizontal: 18,
          borderRadius: 12,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Video
          source={video}                  // the video file
          paused={false}                  // make it start    
          // style={styles.backgroundVideo}  // any style you want
          style={{ height: 300, width: 300 }}  // any style you want
          repeat={true}                   // make it a loop
        /> */}
        {/* 
        <View style={{ height: '80%', width: '80%' }}> */}
        <Image source={require('../../assets/lottie/hello.gif')} style={{ height: 200, width: 300 }} />
        {/* <Lottie
            source={require("../../assets/lottie/eats8.json")}
            style={{ height: 150, width: 150 }}
            autoPlay
            loop
          /> */}

        {/* </View> */}
      </View>
    );
  }
  if (loadHandWave) {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ height: 300, width: 300 }}>
          <Lottie
            source={require("../../assets/lottie/handwave.json")}
            style={{ height: 150, width: 150 }}
            autoPlay
            loop
          />
        </View>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <Animated.View
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 999,
          opacity: tabBarOpacity,
          transform: [{ translateY: tabbatTraslateY }],
          backgroundColor: "#fff",
        }}
      >
        <ShadowedView
          style={{
            shadowOpacity: 0.2,
            shadowRadius: 6,
            shadowOffset: {
              width: 3,
              height: 2,
            },
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#fff",
              paddingVertical: 12,
              elevation: 6,
              shadowOpacity: 1,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("item_search", {
                  country: country
                });
              }}
              style={{
                backgroundColor: "#fff",
                marginHorizontal: 12,
                borderRadius: 12,
                padding: 15,
                borderWidth: 1,
                borderColor: "rgba(0, 0, 0, 0.06)",
                flex: 1,
              }}
            >
              <Text style={{ color: "rgba(82, 81, 86, 0.5)", fontSize: 14 }}>
                Search items
              </Text>
            </TouchableOpacity>

          </View>
        </ShadowedView>
      </Animated.View>
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: {
                  y: scrollY,
                },
              },
            },
          ],
          {
            useNativeDriver: true,
          }
        )}
      >
        <Animated.View
          style={[
            {
              transform: [
                {
                  translateY: coverTranslateY,
                },
              ],
            },
          ]}
        >
          {headerLoading ? null : (
            <HeaderDetailComponent
              bgImage={restDetail?.bgImage}
              logo={restDetail?.logo}
              restDetail={restDetail}
              couponPress={() => { restId && OpenAppRestaurantPage(restId) }}
              reservationPress={() => { restId && OpenAppRestaurantPage(restId) }}
              openMapFunction={() => { restId && OpenAppRestaurantPage(restId) }}
              wavePress={wavePress}
              reportAnIssue={() => { }}
              rateRestaurant={() => { }}
              tableNoExist={tableNoExist}
            // goBack={() => { navigation.goBack() }}
            />
          )}
        </Animated.View>
        <Animated.View
          style={{
            opacity: tabBarOpacityRevers,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("item_search", {
                country: country
              });
            }}
            style={{
              backgroundColor: "#fff",
              marginTop: 13,
              marginHorizontal: 12,
              borderRadius: 12,
              padding: 15,
              borderWidth: 1,
              borderColor: "rgba(0, 0, 0, 0.06)",
              flex: 1,
            }}
          >
            <Text style={{ color: "rgba(82, 81, 86, 0.5)", fontSize: 14 }}>
              Search items
            </Text>
          </TouchableOpacity>
        </Animated.View>
        {sectionLoading && !menuLoading ? (
          <SectionComponent
            sectionList={sectionList}
            changeActiveSection={changeActiveSection}
            activeSection={activeSection}
            openPdf={() => { setSetShowPdf(true) }}
            pdfView={pdfView}
          />
        ) : null}
        {menuLoading && (
          <View
            style={{
              backgroundColor: "#fff",
              marginTop: 10,
              flex: 1,
              paddingVertical: 20,
              marginHorizontal: 18,
              borderRadius: 12,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ height: 150, width: 150 }}>
              <Lottie
                source={require("../../assets/lottie/menu.json")}
                style={{ height: 150, width: 150 }}
                autoPlay
              />
            </View>
          </View>
        )}
        <>
          {!menuLoading && (
            <FlatList
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ paddingBottom: 0 }}
              data={categoryList}
              renderItem={({ item: category }) => {
                return (
                  <View key={category.categoryName}>
                    <PListItem
                      cat_id={category.cat_id}
                      onPress={setExpandedView}
                      categoryName={category.categoryName}
                      count={category.itemList ? category.itemList.length : 0}
                      isExpanded={expandedCategories[category.cat_id]}
                    />
                    {expandedCategories[category.cat_id] && (
                      <FlatList
                        showsVerticalScrollIndicator={false}
                        data={category.itemList}
                        // estimatedItemSize={83.4}
                        ItemSeparatorComponent={() => (
                          <View
                            style={{ height: 5, backgroundColor: "#F4F5FA" }}
                          />
                        )}
                        renderItem={({ item }: any) => {
                          return (
                            <PItemView
                              key={item.item_id}
                              country={country}
                              image={item.image}
                              item_name={item.item_name}
                              price={item.price}
                              kotEnabled={kotEnabled}
                              item_id={item.item_id}
                              reservationsEnabled={
                                restDetail?.reservationsEnabled
                              }
                              status={item.status}
                              tableOrderEnabled={restDetail?.tableOrderEnabled}
                              takeAwayEnabled={restDetail?.takeAwayEnabled}
                              checkAddItem={checkAddItem}
                              item={item}
                              handleAddItemToCart={handleAddItemToCart}
                              token={userToken}
                              rest_id={restDetail?._id}
                              maxDistance={restDetail?.maxDistance}
                              distance={restDetail?.distance}
                            />
                          );
                        }}
                      />
                    )}
                  </View>
                );
              }}
              keyExtractor={(item) => item.cat_id}
              getItemLayout={getItemLayout}
              initialNumToRender={8}
              removeClippedSubviews={true}
              maxToRenderPerBatch={8}
              windowSize={10}
              ListEmptyComponent={() => (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: MECHANFO_RED,
                      textAlign: "center",
                      fontFamily: MECHANFO_FONT_REGULAR,
                    }}
                  >
                    No menu added by the restaurant
                  </Text>
                  {/* <Lottie
                    source={require('../../assets/lottie/empty-menu.json')}
                    style={{ height: 400, width: 400 }}
                    autoPlay
                  /> */}
                </View>
              )}
              ListFooterComponent={() => {
                return <Footer />;
              }}
            />
          )}
        </>

        <View>
          <BottomSheet
            hasDraggableIcon
            ref={bottomSheet}
            sheetBackgroundColor={"#F4F5FA"}
            onCloseFunction={() => onClose()}
            height={windowHeight * 0.75}
          >
            <VariantSheet
              country={country}
              handleAddVariantToCart={handleAddVariantToCart}
              itemImage={itemImage}
              kotEnabled={kotEnabled}
              menuStatus
              restDetail={restDetail}
              variant={variant}
              variantList={variantList}
              variantName={variantName}
            />
          </BottomSheet>
        </View>
      </Animated.ScrollView>
      {CartItemCount(cartItemData) > 0 &&
        //@ts-ignore
        (restDetail.takeAwayEnabled == true || restDetail.reservationsEnabled == true || restDetail.tableOrderEnabled == true) ?
        (<View
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ED1D24',
            flexDirection: 'row',
            width: '100%',
            paddingLeft: 18.75,
            paddingRight: 18.75,
            paddingTop: 20,
            paddingBottom: 20,
          }}>
          <View
            style={{
              flexDirection: 'column',
              width: '50%',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text
                style={{ color: '#fff', fontSize: 20, fontWeight: '700' }}>
                {country}
                {calculateCartTotal(cartItemData)}{' '}
              </Text>
              <View
                style={{
                  height: 18,
                  width: 2.29,
                  backgroundColor: '#fff',
                }}
              />
              <Text
                style={{ color: '#fff', fontSize: 16, fontWeight: '600' }}>
                {' '}
                {CartItemCount(cartItemData)} Item
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '400',
                  color: '#EFEFEF',
                }}>
                Extra Charges may apply
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'column',
              width: '50%',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}>
            <TouchableOpacity
              disabled={CartItemCount(cartItemData) > 0 ? false : true}
              onPress={() => {
                if (CartItemCount(cartItemData) > 0) {
                  if (userToken) {
                    navigation.navigate('orderItem', { page: 'restaurant', restId: restId });
                  } else {
                    // dispatch(clearCart());
                    navigation.navigate('orderItem', { page: 'restaurant', restId: restId });
                    // navigation.navigate('LoginHomeStack', { screen: 'Login' });
                  }

                }
              }}
              style={{
                backgroundColor: '#FFE6E6',
                borderRadius: 8,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 10,
                paddingRight: 10,
              }}>
              <Text
                style={{
                  color: '#ED1D24',
                  fontSize: 16,
                  fontWeight: '600',
                }}>
                View Order
              </Text>
            </TouchableOpacity>
          </View>
        </View>) : null}
      {/* <Modal
        animationType="slide"
        onRequestClose={() => {
          setSetShowPdf(!showPdf);
        }}
        // onClosed={() => { setSetShowPdf(false) }} 
        // onShow={'fullScreen'}
        // coverScreen={false} 
        transparent={true}
        visible={showPdf}
      // isOpen={showPdf}
      >
        <ScrollView stickyHeaderIndices={[0]}>
          <TouchableOpacity
            onPress={() => { setSetShowPdf(false) }}
            style={{
              flex: 1,
              alignItems: 'flex-end',
              backgroundColor: '#fff',
              padding: 20
            }}>
            <Text style={{ color: '#000', fontSize: 16, fontWeight: '600' }}>X</Text>
          </TouchableOpacity>
          <PdfView pdfView={pdfView} />
        </ScrollView>
      </Modal> */}
      {/* {loadHandWave &&
        <View
          style={{
            backgroundColor: "#fff",
            // flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View style={{ height: 300, width: 300 }}>
            <Lottie
              source={require("../../assets/lottie/handwave.json")}
              style={{ height: 150, width: 150 }}
              autoPlay
              loop
            />
          </View>
        </View>} */}
    </View>
  );
};

export default RestuarnatDetail;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F4F5FA",
  },
  headerText: {
    fontSize: 16,
    fontWeight: "600",
    color: "rgba(58, 58, 58, 1)",
  },
  header: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: 20,
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  itemTextStyle: {
    fontFamily: MECHANFO_FONT_REGULAR,
    fontSize: 20,
    fontWeight: "bold",
    color: "#6E7070",
  },
  backgroundVideo: {
    // position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
