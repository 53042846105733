import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import RestuarnatDetail from "./src/screens/RestuarnatDetail";
import { useFonts } from "expo-font";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SearchItemScreen from "./src/screens/SearchItemScreen";

import { Provider } from 'react-redux';
import { store } from './src/components/store';
import React from "react";
import OrderItemScreen from "./src/screens/KOT/ViewCart";
import DigitalCard from "./src/screens/DigitalProfile/DigitalCard";
import OfferListScreen from "./src/screens/DigitalProfile/OfferList";


const Stack = createNativeStackNavigator();

export default function App() {

  const [isAppReady, setIsAppReady] = useState<"DIGITAL_CARD" | "RESTAURANT" | undefined>()

  useEffect(() => {

    const url = window.location.href;  // Get the current URL

    const containsRestaurant = url.includes("/restaurant/");
    const containsMenu = url.includes("/digitalcard/");
    if (containsRestaurant) {
      setIsAppReady("RESTAURANT")
    } else if (containsMenu) {
      setIsAppReady("DIGITAL_CARD")
    }

    return () => {
    }
  }, [])



  const [fontsLoaded] = useFonts({
    "Poppins-Black": require("./assets/fonts/Poppins-Black.ttf"),
    "Poppins-ExtraBold": require("./assets/fonts/Poppins-ExtraBold.ttf"),
    "Poppins-Regular": require("./assets/fonts/Poppins-Regular.ttf"),
    "Poppins-SemiBold": require("./assets/fonts/Poppins-SemiBold.ttf"),
  });

  if (!fontsLoaded) {
    return null;
  }

  if (!isAppReady) {
    return null
  }

  return (
    <Provider store={store}>
      <NavigationContainer documentTitle={{
        enabled: false
      }}>
        <Stack.Navigator>
          {isAppReady === "DIGITAL_CARD" && (<Stack.Screen options={{ headerShown: false }} name="digital_card" component={DigitalCard} />)}
          {isAppReady === "RESTAURANT" && (<Stack.Screen options={{ headerShown: false }} name="restaurant_home" component={RestuarnatDetail} />)}
          <Stack.Screen options={{ headerShown: false }} name="item_search" component={SearchItemScreen} />
          <Stack.Screen options={{ headerShown: false }} name="orderItem" component={OrderItemScreen} />
          <Stack.Screen options={{ headerShown: false }} name="offerlist" component={OfferListScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
