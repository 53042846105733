import * as React from "react"
const TIKTOKSVG = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={31}
        height={31}
        clipRule="evenodd"
        viewBox="0 0 48 48"
        {...props}
    >
        <path
            fill="#212121"
            fillRule="evenodd"
            d="M10.904 6h26.191A4.905 4.905 0 0 1 42 10.904v26.191A4.905 4.905 0 0 1 37.096 42H10.904A4.905 4.905 0 0 1 6 37.096V10.904A4.905 4.905 0 0 1 10.904 6z"
        />
        <path
            fill="#ec407a"
            fillRule="evenodd"
            d="M29.208 20.607a9.587 9.587 0 0 0 5.592 1.788v-4.011c-.395 0-.788-.041-1.174-.123v3.157a9.596 9.596 0 0 1-5.592-1.788v8.184a7.415 7.415 0 0 1-7.417 7.413 7.385 7.385 0 0 1-4.129-1.254 7.398 7.398 0 0 0 5.303 2.23 7.415 7.415 0 0 0 7.417-7.413v-8.183zm1.449-4.046a5.594 5.594 0 0 1-1.449-3.273v-.516h-1.113a5.616 5.616 0 0 0 2.562 3.789zM19.079 30.832a3.392 3.392 0 0 1 3.73-5.285v-4.1a7.552 7.552 0 0 0-1.174-.068v3.191a3.392 3.392 0 0 0-2.556 6.262z"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M28.034 19.63a9.587 9.587 0 0 0 5.592 1.788v-3.157a5.62 5.62 0 0 1-2.969-1.701 5.613 5.613 0 0 1-2.561-3.788h-2.923V28.79a3.392 3.392 0 0 1-6.094 2.041 3.39 3.39 0 0 1 1.527-6.42c.359 0 .705.056 1.03.159v-3.19a7.414 7.414 0 0 0-5.146 12.594 7.385 7.385 0 0 0 4.129 1.254 7.415 7.415 0 0 0 7.417-7.413l-.002-8.185z"
        />
        <path
            fill="#81d4fa"
            fillRule="evenodd"
            d="M33.626 18.262v-.854a5.586 5.586 0 0 1-2.969-.848 5.605 5.605 0 0 0 2.969 1.702zm-5.531-5.49a5.484 5.484 0 0 1-.061-.461v-.516h-4.036v16.019a3.392 3.392 0 0 1-4.919 3.017 3.392 3.392 0 0 0 6.094-2.041V12.772h2.922zm-6.46 8.608v-.909a7.415 7.415 0 0 0-5.147 13.503 7.384 7.384 0 0 1-2.114-5.183 7.415 7.415 0 0 1 7.261-7.411z"
        />
    </svg>
)
export default TIKTOKSVG