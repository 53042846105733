import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { MECHANFO_TEXT_COLOR } from "../../../screens/import";
import { useAppSelector } from "../../store/reduxHooks";
import { Cart } from "../../../screens/KOT/cartSlice";
import { calculateCartTotal } from "../../Restaurant/utils/price";

export const ExistingActiveItem = ({ activeOrderList, currencySymbol, restDetail, navigation }: any) => {
    const { cartItemData, } = useAppSelector(Cart);
    let active_order = activeOrderList;

    return (
        <View style={[styles.cardContainer]}>
            {active_order.items.map((item: any, index: any, row: any) => {
                return (
                    <View key={item._id}>
                        <View style={{ overflow: 'hidden' }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: 10,
                                    paddingBottom: 8,
                                }}>
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        flex: 1,
                                    }}>
                                    <View
                                        style={[
                                            { flexDirection: 'row' },
                                            item.variantExists
                                                ? {
                                                    backgroundColor: '#F4F5FA',
                                                    padding: 4,
                                                    alignItems: 'center',
                                                }
                                                : {},
                                        ]}>
                                        <Text
                                            style={{
                                                color: MECHANFO_TEXT_COLOR,
                                                fontSize: item.variantExists ? 12 : 14,
                                                fontWeight: '600',
                                            }}>
                                            {item.itemName}
                                        </Text>
                                    </View>
                                    {item.variantExists &&
                                        Object.values(item.variant).length > 0 ? (
                                        <View>
                                            {Object.values(item.variant).map(
                                                (vItem: any) => {
                                                    return (
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                marginTop: 8,
                                                                justifyContent: 'space-between',
                                                            }}
                                                            key={vItem._id}>
                                                            <View
                                                                style={{ flexDirection: 'column' }}>
                                                                <Text
                                                                    style={{
                                                                        color: MECHANFO_TEXT_COLOR,
                                                                        fontSize: 14,
                                                                        fontWeight: '600',
                                                                    }}>
                                                                    {vItem.variantName}
                                                                </Text>
                                                                <Text
                                                                    style={{
                                                                        color: MECHANFO_TEXT_COLOR,
                                                                        fontSize: 15,
                                                                        fontWeight: '500',
                                                                    }}>
                                                                    {currencySymbol}
                                                                    {vItem.price}
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'column',
                                                                }}>
                                                                <View style={styles.button}>
                                                                    <View
                                                                        style={styles.buttonSmall}>
                                                                        <Text style={[styles.font, { color: 'rgba(195, 195, 195, 1)' }]}>-</Text>
                                                                    </View>
                                                                    <Text style={[styles.font, { fontSize: 16 }]}>{item.quantity}</Text>
                                                                    <View
                                                                        style={styles.buttonSmall}>
                                                                        <Text style={[styles.font, { color: 'rgba(195, 195, 195, 1)' }]}>+</Text>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    );
                                                },
                                            )}
                                        </View>
                                    ) : null}
                                    {!item.variantExists && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text
                                                style={{
                                                    color: MECHANFO_TEXT_COLOR,
                                                    fontSize: 16,
                                                    fontWeight: '500',
                                                }}>
                                                {currencySymbol}
                                                {item.price}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                                {!item.variantExists && (
                                    <View
                                        style={{
                                            flexDirection: 'column',
                                        }}>
                                        <View style={styles.button}>
                                            <View
                                                style={styles.buttonSmall}>
                                                <Text style={[styles.font, { color: 'rgba(195, 195, 195, 1)' }]}>-</Text>
                                            </View>
                                            <Text style={[styles.font, { fontSize: 16 }]}>{item.quantity}</Text>
                                            <View
                                                style={styles.buttonSmall}>
                                                <Text style={[styles.font, { color: 'rgba(195, 195, 195, 1)' }]}>+</Text>
                                            </View>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                        {index === row.length - 1
                            && Object.keys(cartItemData).length == 0 && active_order.paymentMode == 6
                            && active_order.paymentStatus == 0
                            && active_order.orderStatus != 0 ? (
                            <View
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: 8,
                                    width: '100%',
                                }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View
                                        style={{
                                            borderColor: '#F0F0F0',
                                            borderWidth: 1.5,
                                            width: '100%',
                                            marginTop: 15,
                                        }}
                                    />
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (restDetail._id == 'restaurant') {
                                                navigation.goBack();
                                            } else {
                                                navigation.navigate('RestuarantDeatil', {
                                                    rest_id: restDetail._id,
                                                    data: restDetail,
                                                    item_id: undefined,
                                                })
                                            }
                                        }

                                        }>
                                        <Text
                                            style={{
                                                fontSize: 16,
                                                fontWeight: '600',
                                                color: '#ED1D24',
                                            }}>
                                            + Add more item
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        ) : null}
                    </View>
                );
            })}
        </View>)
};

export const ActiveBillDetails = ({ activeOrderList, currencySymbol, restDetail, type }: any) => {
    const { cartItemData, activeOrderRestaurant } = useAppSelector(Cart);
    let item_total = 0;

    const calculateGst = (gstType: any) => {
        if (activeOrderRestaurant.gstEnable) {
            if (type == 1) {
                item_total = activeOrderList.itemTotal;
            } else {
                item_total = calculateCartTotal(cartItemData) + activeOrderList.itemTotal;
            }
            if (gstType == 1) {
                let cgst_amt =
                    (item_total * activeOrderRestaurant.cgst) / 100;
                return cgst_amt;
            } else {
                let sgst_amt =
                    (item_total * activeOrderRestaurant.sgst) / 100;
                return sgst_amt;
            }
        } else {
            return 0;
        }

    };

    const calculateTotal = () => {
        if (type == 1) {
            item_total = activeOrderList.itemTotal;
        } else {
            item_total = calculateCartTotal(cartItemData) + activeOrderList.itemTotal;
        }
        let value = item_total +
            calculateGst(1) +
            calculateGst(2)
        return (
            value.toFixed(2)
        );
    }

    return (
        <View style={[styles.cardContainer, { marginTop: 20 }]}>
            <View style={{ flexDirection: 'column' }}>
                <Text style={[styles.billTitleText]}>Bill Details</Text>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: 10,
                    }}>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '60%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <Text style={[styles.billDetailText]}>Item Total</Text>
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '38%',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}>
                        <Text style={[styles.billPriceText]}>
                            {currencySymbol}
                            {type == 1 ? activeOrderList.itemTotal : (activeOrderList.itemTotal + calculateCartTotal(cartItemData))}
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: 10,
                    }}>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '60%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <Text style={[styles.billDetailText]}>
                            CGST Amount ({activeOrderRestaurant.cgst}%)
                        </Text>
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '38%',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}>
                        <Text style={[styles.billPriceText]}>
                            {currencySymbol}
                            {calculateGst(1)}
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: 10,
                    }}>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '60%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <Text style={[styles.billDetailText]}>
                            SGST Amount ({activeOrderRestaurant.sgst}%)
                        </Text>
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '38%',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}>
                        <Text style={[styles.billPriceText]}>
                            {currencySymbol}
                            {calculateGst(2)}
                        </Text>
                    </View>
                </View>
                <View style={{ overflow: 'hidden', marginTop: 10 }}>
                    <View
                        style={{
                            borderStyle: 'dashed',
                            borderWidth: 1,
                            borderColor: '#B4B4B4',
                            margin: -1,
                            height: 0,
                            marginBottom: 0,
                        }}>
                        <View style={{ width: 60 }}></View>
                    </View>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: 10,
                    }}>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '60%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <Text
                            style={[styles.billDetailText, { fontWeight: '600' }]}>
                            Total
                        </Text>
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            width: '38%',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}>
                        <Text
                            style={[
                                styles.billPriceText,
                                { fontSize: 14, fontWeight: '600' },
                            ]}>
                            {currencySymbol}
                            {calculateTotal()}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    cardContainer: {
        backgroundColor: '#fff',
        borderRadius: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 10,
    },
    button: {
        flexDirection: 'row',
        backgroundColor: 'rgba(243, 244, 249, 1)',
        width: 80,
        height: 38,
        borderRadius: 9,
        justifyContent: 'space-around',
        alignItems: 'center',
        alignContent: 'center',
    },
    buttonSmall: {
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        height: 32,
        width: 25,
    },
    font: { fontFamily: 'Poppins-SemiBold', fontSize: 18, color: 'rgba(84, 84, 84, 1)' },
    billTitleText: {
        color: MECHANFO_TEXT_COLOR,
        fontSize: 14,
        fontWeight: '600',
    },
    billPriceText: {
        color: MECHANFO_TEXT_COLOR,
        fontSize: 16,
        fontWeight: '500',
    },
    billDetailText: {
        color: MECHANFO_TEXT_COLOR,
        fontSize: 14,
        fontWeight: '400',
    },
});
