// COLORS
// export const MECHANFO_FONT = 'serif';
export const MECHANFO_RED = '#ED1D24';
export const MECHANFO_BORDER = '#f7c7bc';
export const MECHANFO_BORDER_COLOR = '#FFEDEE';
export const MECHANFO_GRAY = '#EAEAEA';
export const MECHANFO_BG = '#F8F4F4';
export const MECHANFO_BUTTON = '#CB202D';
export const MECHANFO_FILL = '#fdf3f1';
export const MECHANFO_GREEN = '#2E8B57';
export const MECHANFO_BOX = '#FBE8E4';
export const MECHANFO_SEARCH_H = '#df1218';
export const MECHANFO_FONT_FAMILY = 'Poppins-ExtraBold';
export const MECHANFO_FONT_REGULAR = 'Poppins-Regular';
export const MECHANFO_PLACEHOLDERCOLOR = '#989EB1';
export const MECHANFO_TEXT_COLOR = '#3A3A3A';
export const MECHANFO_BG_NEW = '#F4F5FA';
