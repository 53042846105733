/* eslint-disable @typescript-eslint/no-unused-vars */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { any } from 'prop-types';
import { RootState } from '../../components/store/rootReducer';

interface cartItem {
  cart_restaurant: any;
  cartItemData: any;
  bills: [];
  created_at: any;
  delivery_mode: number;
  cartItemTotalData: any;
  payment_mode: number;
  cartCouponId: any;
  cartCouponBalance: any;
  cart_coupon_flag: boolean;
  cartCouponIsPercentage: number;
  activeDineInOrder: any;
  activeOrderRestaurant: any;
  walletId: any,
  cartmCardBalance: any,
  wallet_flag: any,
}

const initialState: cartItem = {
  cart_restaurant: {},
  cartItemData: {},
  bills: [],
  created_at: false,
  delivery_mode: 0,
  cartItemTotalData: {},
  payment_mode: 0,
  cartCouponId: null,
  cartCouponBalance: 0,
  cartCouponIsPercentage: 0,
  cart_coupon_flag: false,
  activeDineInOrder: {},
  activeOrderRestaurant: {},
  walletId: null,
  cartmCardBalance: 0,
  wallet_flag: 0,
};

const cartSlice = createSlice({
  name: 'cartItem',
  initialState,
  reducers: {
    addToCart: (
      state,
      action: PayloadAction<{ cartItems: any; restaurant: any }>,
    ) => {
      state.cartItemData = action.payload.cartItems;

      if (action.payload.restaurant) {
        state.cart_restaurant = action.payload.restaurant;
        state.created_at = Date.now();
      }
    },
    clearCart: state => {
      state.bills = [];
      state.cartItemData = {};
      state.cart_restaurant = {};
      state.created_at = false;
      state.delivery_mode = 0;
      state.cartItemTotalData = {};
      state.payment_mode = 0;
      state.cartCouponId = null;
      state.cartCouponBalance = 0;
      state.cartCouponIsPercentage = 0;
      state.walletId = null;
      state.cartmCardBalance = 0;
      state.wallet_flag = 0;
    },
    removeFromCart: (state, action: PayloadAction<{ data: any }>) => {
      const removeFromCart = state.cartItemData.filter(
        (item: any) => item.id !== action.payload.data.id,
      );
      state.cartItemData = removeFromCart;
    },
    setDeliveryMode: (state, action: PayloadAction<{ mode: any }>) => {
      state.delivery_mode = action.payload.mode;
    },
    setCartItemTotal: (state, action: PayloadAction<{ data: any }>) => {
      state.cartItemTotalData = action.payload.data;
    },
    setPaymentMode: (state, action: PayloadAction<{ mode: any }>) => {
      state.payment_mode = action.payload.mode;
    },
    selectedCouponId: (
      state,
      action: PayloadAction<{
        id: any;
        balance: any;
        cartCouponIsPercentage: any;
      }>,
    ) => {
      state.cartCouponId = action.payload.id;
      state.cartCouponBalance = action.payload.balance;
      state.cartCouponIsPercentage = action.payload.cartCouponIsPercentage;
    },
    setCartCouponFlag: (state, action: PayloadAction<{ flag: any }>) => {
      state.cart_coupon_flag = action.payload.flag;
    },
    setActiveDineInOrder: (state, action: PayloadAction<{ data: any }>) => {
      state.activeDineInOrder = action.payload.data;
    },
    setActiveOrderRestaurant: (state, action: PayloadAction<{ data: any }>) => {
      state.activeOrderRestaurant = action.payload.data;
    },
    setMcardSelected: (state, action: PayloadAction<{
      walletId: any;
      cartmCardBalance: any;
      wallet_flag: any;
    }>) => {
      state.walletId = action.payload.walletId;
      state.cartmCardBalance = action.payload.cartmCardBalance;
      state.wallet_flag = action.payload.wallet_flag;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  setDeliveryMode,
  setCartItemTotal,
  setPaymentMode,
  selectedCouponId,
  setCartCouponFlag,
  setActiveDineInOrder,
  setActiveOrderRestaurant,
  setMcardSelected,
} = cartSlice.actions;

export const AddItemToCart = (state: RootState) => state.cartItem;
export const Cart = (state: RootState) => state.cartItem;

export default cartSlice.reducer;
