import {
  Image,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React from 'react';
import { MECHANFO_FONT_REGULAR, MECHANFO_TEXT_COLOR } from '../import';
import ItemButton from '../../components/Restaurant/ItemButton';
import { useSelector } from 'react-redux';
import { Cart } from '../KOT/cartSlice';
import { handleChangeVariantItemQuantity } from '../KOT/CartUtils';
import { useAppDispatch } from '../../components/store/reduxHooks';
type Props =
  {
    variantList: any;
    itemImage: any;
    variantName: any;
    country: any;
    restDetail: any;
    variant: any;
    kotEnabled: boolean;
    menuStatus: boolean;
    handleAddVariantToCart: any;
  };

const VariantSheet = ({
  variantList,
  itemImage,
  variantName,
  country,
  restDetail,
  variant,
  kotEnabled,
  menuStatus,
  handleAddVariantToCart,
}: Props) => {
  //redux
  const { cartItemData, cart_restaurant, activeDineInOrder } = useSelector(Cart);
  const dispatch = useAppDispatch();
  return (
    <View style={{ backgroundColor: '#F4F5FA', marginTop: 2 }}>
      <StatusBar backgroundColor="rgba(0,0,0,0.5)" />
      <View
        style={{
          margin: 20,
          borderRadius: 16,
          backgroundColor: '#fff',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: -10,
          marginTop: 10,
          height: 200,
        }}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: -30,
            width: '75%',
            height: '75%',
          }}>
          <Image
            source={{ uri: itemImage }}
            style={[
              {
                borderRadius: 16,
                width: '125%',
                height: '97%',
                backgroundColor: '#E0E0E0',
              },
            ]}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 10,
            marginBottom: -30,
          }}>
          <Text
            style={[
              styles.itemTextStyle,
              { color: '#000', fontSize: 16, marginTop: 8, marginBottom: 4 },
            ]}>
            {variantName}{' '}
          </Text>
        </View>
      </View>
      <View>
        {variantList && (
          <View
            style={{
              marginTop: 0,
              margin: 20,
              borderRadius: 16,
              backgroundColor: '#fff',
              paddingHorizontal: 18,
              paddingVertical: 10,
            }}>
            <View style={{}}>
              <Text
                style={[
                  styles.itemTextStyle,
                  {
                    fontSize: 15,
                    color: MECHANFO_TEXT_COLOR,
                    // marginLeft:18
                  },
                ]}>
                Variant
              </Text>
              <View
                style={{
                  height: 1,
                  backgroundColor: '#D1D1D1',
                  marginBottom: 5,
                  marginTop: 5,
                }}
              />
            </View>
            {variantList.map((varii: any, i: any) => {
              return (
                <View key={varii._id}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 10,
                      // marginRight: 20,
                    }}>
                    <>
                      <View
                        style={{
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                          width: '45%',
                        }}>
                        {varii.variantName && (
                          <Text
                            style={[
                              styles.textStyle,
                              {
                                color: '#000',
                                fontSize: 14,
                              },
                            ]}>
                            {varii.variantName}
                          </Text>
                        )}
                      </View>
                      <View
                        style={{
                          flexDirection: 'column',
                          // width: '25%',
                        }}>
                        {varii.price && (
                          <Text
                            style={[
                              styles.textStyle,
                              {
                                color: '#000',
                                fontSize: 14,
                              },
                            ]}>
                            {country} {varii.price}
                          </Text>
                        )}
                      </View>
                      {/* <View
                        style={{
                          flexDirection: 'column',
                          // width: '30%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        {kotEnabled == true &&
                          menuStatus == true &&
                          ((restDetail && restDetail.takeAwayEnabled == true) ||
                            restDetail?.reservationsEnabled == true ||
                            restDetail?.tableOrderEnabled) ? (
                          variant &&
                            cartItemData[variant.item_id] &&
                            cartItemData[variant.item_id]['variant'] &&
                            cartItemData[variant.item_id]['variant'][varii._id] &&
                            cartItemData[variant.item_id]['variant'][varii._id]
                              .quantity ? (
                            <ItemButton
                              handleDecrement={() => {
                                handleChangeVariantItemQuantity(
                                  cartItemData,
                                  dispatch,
                                  variant,
                                  varii,
                                  0,
                                );
                              }}
                              handleIncrement={() => {
                                handleChangeVariantItemQuantity(
                                  cartItemData,
                                  dispatch,
                                  variant,
                                  varii,
                                  1,
                                );
                              }}
                              count={
                                variant &&
                                  cartItemData[variant.item_id] &&
                                  cartItemData[variant.item_id]['variant'] &&
                                  cartItemData[variant.item_id]['variant'][
                                  varii._id
                                  ] &&
                                  cartItemData[variant.item_id]['variant'][
                                    varii._id
                                  ].quantity
                                  ? cartItemData[variant.item_id]['variant'][
                                    varii._id
                                  ].quantity
                                  : 0
                              }
                            />
                          ) : (
                            <TouchableOpacity
                              onPress={() => {
                                handleAddVariantToCart(variant, varii);
                              }}
                              style={{
                                backgroundColor: '#FFE6E6',
                                borderRadius: 8,
                                borderWidth: 1,
                                borderColor: '#FFA7A7',
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                                height: 35,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                              <Text
                                style={{
                                  color: '#ED1D24',
                                  fontSize: 16,
                                  fontWeight: '600',
                                }}>
                                ADD
                              </Text>
                            </TouchableOpacity>
                          )
                        ) : (
                          null
                          // <View>
                          //   <Text
                          //     style={{
                          //       color: '#555555',
                          //       fontSize: 12,
                          //       fontWeight: '600',
                          //     }}>
                          //     Not available
                          //   </Text>
                          // </View>
                        )}
                      </View> */}
                    </>
                  </View>
                </View>
              );
            })}
          </View>
        )}
      </View>
    </View>
  );
};

export default VariantSheet;

const styles = StyleSheet.create({
  itemTextStyle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#6E7070',
    fontFamily: 'Poppins-SemiBold',
  },
  textStyle: {
    color: '#000',
    // fontWeight: 'bold',
    fontFamily: 'Poppins-SemiBold',
  },
});
