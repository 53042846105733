import * as React from "react"
import Svg, { Path } from "react-native-svg"
const RIGHTSVG = (props: any) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        {...props}
    >
        <Path
            stroke="#121212"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3.75 9h10.5M9.75 13.5l4.5-4.5M9.75 4.5l4.5 4.5"
        />
    </Svg>
)
export default RIGHTSVG