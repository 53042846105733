import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const DownloadICon = (props: SvgProps) => (
  <Svg
  //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={25}
    fill="none"
    {...props}
  >
    <Path
      fill="#ED1D24"
      d="M25.704 9.5C25.303 4.398 21.023.368 15.819.368a9.847 9.847 0 0 0-9.505 7.108 8.818 8.818 0 0 0-5.92 8.317c0 4.86 3.954 8.815 8.814 8.815h14.323c4.253 0 7.713-3.46 7.713-7.713a7.678 7.678 0 0 0-5.54-7.395Zm-4.7 5.97-4.406 4.408a1.1 1.1 0 0 1-1.558 0l-4.407-4.408a1.102 1.102 0 0 1 .779-1.88h2.203V9.183a2.203 2.203 0 1 1 4.407 0v4.407h2.204a1.104 1.104 0 0 1 .779 1.88Z"
    />
  </Svg>
)
export default DownloadICon
