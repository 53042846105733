import {
  Image,
  ImageBackground,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
  Modal,
  ScrollView,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import SaveSvg from "./assets/SAVE";
import CallSVG from "./assets/Call";
import MailSvg from "./assets/Mail";
import FooterBlack from "./FooterBlack";
import TwitterSVG from "./assets/socialicons/twitter";
import GOOGLEREVIEWSVG from "./assets/socialicons/GoogleReview";
import {
  ChatOnWhatsapp,
  Facebook,
  Instagram,
  Linkdin,
} from "./assets/socialicons/ICONS";
import Lottie from "lottie-react-native";
import TIKTOKSVG from "./assets/Tiktok";
import YOUTUBESVG from "./assets/Youtube";
import GOOGLEMAPSVG from "./assets/socialicons/GoogleMap";
import EATSSVG from "./assets/socialicons/Eats";
import { base_url_digital, checkNewUser, getOfferList } from "../../api/api";
//@ts-ignore
// import Modal from 'react-native-modalbox';
import PdfView from "../PDFView/PdfView";
import PDFIcon from "../PDFView/PDFIcon";
import QRIcon from "./assets/QRIcon";
import QRModal from "./QRModal";
import RegisterCard from "./RegisterCard";
//@ts-ignore
import RIGHTSVG from "./assets/RightArrow";
type Props = {};

const DigitalCard = ({ navigation }: any) => {
  const [Details, setDetails] = useState<any>();
  const [loader, setLoader] = useState(true);
  const [showPdf, setSetShowPdf] = useState(false)
  const [showQr, setSetShowQr] = useState(false)
  const [existingUser, setExistingUser] = useState(false);
  const [offerArray, setOfferArray] = useState([]);
  const [noData, setNodata] = useState(false);
  const animationRef = useRef<Lottie>(null);

  useEffect(() => {
    getDetails();
    animationRef.current?.play();

    // Or set a specific startFrame and endFrame with:
    animationRef.current?.play(30, 120);
    return () => { };
  }, []);

  const queryParams = new URLSearchParams(window.location.search);

  const getDetails = async () => {
    try {
      const BASE = window.location.origin;
      const res_name = window.location.href.split(BASE + "/digitalcard/")[1];
      // const uniqueId = queryParams.get('uniqueId');
      checkNewUser(async (list: any) => {
        try {
          // if (list.status == true) {
          setExistingUser(list.newUser);
          setNodata(false);
          if (list.newUser == false) {
            const response = await axios.get(
              base_url_digital + `users/digitalCard/?uniqueId=${res_name}`
            );
            setDetails(response.data.data);
            getOfferList((list: any) => {
              setOfferArray(list.data);
            }, response.data.data.countryId);
          }
          setTimeout(() => {
            setLoader(false);
          }, 1500);
          // } else {
          //   setNodata(true);
          //   setTimeout(() => {
          //     setLoader(false);
          //   }, 1500);
          // }
        } catch (err) {
          console.log(err);
        }
      }, res_name);
    } catch (error) {
      console.log(error);
    }
  };

  const { height, width } = useWindowDimensions();

  const renderIcon = (name: string) => {
    if (name === "Eats") {
      return <EATSSVG style={{ marginRight: 8 }} />;
    }
    if (name === "Instagram") {
      return <Instagram style={{ marginRight: 8 }} />;
    }
    if (name === "Facebook") {
      return <Facebook style={{ marginRight: 8 }} />;
    }
    if (name === "Linkedin") {
      return <Linkdin style={{ marginRight: 8 }} />;
    }
    if (name === "X") {
      return <TwitterSVG style={{ marginRight: 8 }} />;
    }
    if (name === "Google Review") {
      return <GOOGLEREVIEWSVG style={{ marginRight: 8 }} />;
    }
    if (name === "Tik Tok") {
      return <TIKTOKSVG style={{ marginRight: 8 }} />;
    }
    if (name === "Youtube") {
      return <YOUTUBESVG style={{ marginRight: 8 }} />;
    }
    if (name === "Google Map") {
      return <GOOGLEMAPSVG style={{ marginRight: 8 }} />;
    }
  };

  if (loader) {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          marginTop: 10,
          flex: 1,
          paddingVertical: 20,
          marginHorizontal: 18,
          borderRadius: 12,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ height: 300, width: 300 }}>
          {/* <Lottie
            source={require("../../../assets/lottie/eats8.json")}
            style={{ height: 150, width: 150 }}
            autoPlay
            loop
          /> */}
          <Image source={require('../../../assets/lottie/hello.gif')} style={{ height: 200, width: 300 }} />
        </View>
      </View>
    );
  }

  return (

    <View style={styles.container}>
      {
        noData == true ?
          <View style={{ backgroundColor: "#fff", paddingBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
            <Lottie
              ref={animationRef}
              source={require('../../../assets/lottie/noData.json')}
              style={{ height: 100, width: 100 }}
              autoPlay
              loop
            />
            <Text style={{ color: '#000', fontSize: 20, fontWeight: '600' }}>No data Found</Text>
          </View>
          :
          existingUser == true ?
            <>
              <RegisterCard />
            </>
            :
            <View>
              <View style={{ backgroundColor: "#fff", paddingBottom: 20 }}>
                <View>
                  <Image
                    style={{
                      borderBottomLeftRadius: 18,
                      borderBottomRightRadius: 18,
                      width: width,
                      height: 200,
                    }}
                    source={{ uri: Details.bgImage }}
                  ></Image>
                  <Image
                    style={{
                      width: 125,
                      height: 125,
                      position: "absolute",
                      top: 110,
                      left: (width - 125) / 2,
                      borderWidth: 5,
                      borderColor: "#fff",
                      borderRadius: 100,
                    }}
                    source={{ uri: Details.logo }}
                    resizeMode="cover"
                  />
                </View>
                <View style={{ alignItems: "center", justifyContent: "center" }}>
                  <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                    <Text style={[styles.textNameStyle]}>{Details.name}</Text>
                    <TouchableOpacity
                      onPress={() => { setSetShowQr(true) }}
                      style={{
                        borderWidth: 1,
                        borderColor: "#F4F5FA",
                        borderRadius: 30,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 40,
                        marginLeft: 10,
                        backgroundColor: "rgba(91, 187, 253, 0.05)",
                      }}
                    >
                      <QRIcon />
                    </TouchableOpacity>
                  </View>
                  <Text style={[styles.textStyle]}>{Details.aboutUs}</Text>
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      borderColor: "#F4F5FA",
                      borderRadius: 30,
                      paddingHorizontal: 12,
                      paddingVertical: 6,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 12,
                    }}
                    onPress={() => {
                      // const BASE1 = window.location.origin;
                      // const res_name1 = window.location.href.split(
                      //   BASE1 + "/web/"
                      // )[1];
                      // Linking.openURL(
                      //   `https://mechanfo-development.el.r.appspot.com/digitalcard/${res_name1}`
                      // );
                      const BASE = window.location.origin;
                      const res_name = window.location.href.split(BASE + "/digitalcard/")[1];
                      Linking.openURL(
                        base_url_digital + `users/digitalcard/exportContact/${res_name}`
                      );
                    }}
                  >
                    <SaveSvg />
                    <Text style={[styles.textBtnStyle]}>{"Save Contact"}</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  paddingVertical: 12,
                  backgroundColor: "#fff",
                  alignItems: "center",
                  marginTop: 12,
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: "#F4F5FA",
                    borderRadius: 30,
                    paddingHorizontal: 18,
                    paddingVertical: 6,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(91, 187, 253, 0.05)",
                  }}
                  onPress={() => {
                    Linking.openURL(`tel:${Details.phone}`);
                  }}
                >
                  <CallSVG />
                  <Text style={[styles.textBtnStyle, { color: "#0098FF" }]}>
                    {"Call Now"}
                  </Text>

                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: "#F4F5FA",
                    borderRadius: 30,
                    paddingHorizontal: 18,
                    paddingVertical: 6,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginStart: 12,
                    backgroundColor: "rgba(217, 246, 245, 0.50)",
                  }}
                  onPress={() => {
                    Linking.openURL(`mailto:${Details.email}`);
                  }}
                >
                  <MailSvg />
                  <Text style={[styles.textBtnStyle, { color: "#04A39B" }]}>
                    {"Send a mail"}
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  backgroundColor: "#fff",
                  marginTop: 12,
                  padding: 16,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(`${Details.whatsapp}`);
                  }}
                >
                  <ChatOnWhatsapp />
                </TouchableOpacity>
              </View>
              <View style={{ backgroundColor: "#F4F5FA", height: 12 }} />
              <View style={{ backgroundColor: "#fff", marginTop: 12, padding: 16 }}>
                <Text
                  style={{
                    marginBottom: 0,
                    color: "#424141",
                    fontFamily: "Poppins-Semibold",
                    marginEnd: 12,
                  }}
                >
                  Social Links
                </Text>
                <View style={{ flexDirection: "row", marginTop: 12 }}>
                  {Details.sociallinks && Details.sociallinks.length > 0
                    ? Details.sociallinks.map((item: any) => {
                      return (
                        item.url != '' ?
                          <TouchableOpacity
                            key={item.url}
                            onPress={() => {
                              Linking.openURL(`${item.url}`);
                            }}
                          >
                            {renderIcon(item.label)}
                          </TouchableOpacity> : null
                      );

                    })
                    : null}
                  {/* {Details.pdfMenu ?
            <TouchableOpacity onPress={() => { setSetShowPdf(true) }}>
              <PDFIcon />
            </TouchableOpacity>
            : null} */}
                </View>
              </View>
              {/* {showPdf ?
        <View>
          <Modal
            animationType="slide"
            onRequestClose={() => {
              setSetShowPdf(!showPdf);
            }}
            transparent={true}
            visible={showPdf}
          >
            <ScrollView stickyHeaderIndices={[0]}>
              <TouchableOpacity
                onPress={() => { setSetShowPdf(false) }}
                style={{
                  flex: 1,
                  alignItems: 'flex-end',
                  backgroundColor: '#fff',
                  padding: 20
                }}>
                <Text style={{ color: '#000', fontSize: 16, fontWeight: '600' }}>X</Text>
              </TouchableOpacity>
              <PdfView pdfView={Details.pdfMenu} />
            </ScrollView>
          </Modal>
        </View> : null} */}
              <View style={{ backgroundColor: "#F4F5FA", height: 12 }}></View>

              <View style={{ backgroundColor: "#F4F5FA", height: 12 }} />

              <View style={{ backgroundColor: "#fff", padding: 16 }}>
                <Text
                  style={{
                    marginBottom: 0,
                    color: "#424141",
                    fontFamily: "Poppins-Semibold",
                    marginEnd: 12,
                  }}
                >
                  Address
                </Text>
                <Text
                  style={{
                    marginBottom: 0,
                    color: "#707277",
                    fontFamily: "Poppins-Regular",
                    marginEnd: 12,
                  }}
                >
                  {Details.address}
                </Text>
              </View>
              <View style={{ backgroundColor: "#F4F5FA", height: 12 }} />
              {offerArray.length > 0 ?
                <View style={{ backgroundColor: "#fff", padding: 16 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                    <View style={{ flexDirection: 'column', flex: 3 }}>
                      <Text style={{ color: '#121212', fontWeight: '600', fontSize: 16, fontFamily: "Poppins-Regular" }}>⚡ Partner Offers for you </Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => { navigation.navigate('offerlist', { list: offerArray }) }}
                      style={{ flexDirection: 'column', flex: 2, alignItems: 'flex-end' }}>
                      <Text style={{ color: '#0098FF', fontWeight: '600', fontSize: 16, fontFamily: "Poppins-Regular" }}>View All</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 20 }}>
                    <ScrollView
                      horizontal
                      showsHorizontalScrollIndicator={false}
                    >
                      {offerArray.map((offers: any) => {
                        return (
                          <View key={offers._id} style={{ paddingRight: 16 }}>
                            <View style={{ backgroundColor: '#F4F5FA' }}>
                              <Image source={offers.image} style={{ height: 256.25, width: 256.25 }} />
                              <View style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 20, flexDirection: 'row', flex: 1 }}>
                                <View style={{ flexDirection: 'column', flex: 3 }}>
                                  <Text style={{ color: '#121212', fontSize: 18, fontWeight: '500' }}>{offers.title}</Text>
                                  <Text style={{ color: '#807E7E', fontSize: 12, fontWeight: '400', paddingTop: 10 }}>{offers.description}</Text>
                                </View>
                                <View style={{ flexDirection: 'column', flex: 2, alignItems: 'flex-end', paddingRight: 10 }}>
                                  <TouchableOpacity
                                    onPress={() => {
                                      Linking.openURL(offers.link);
                                    }}
                                    style={{ borderRadius: 40 / 2, height: 40, width: 40, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center' }}
                                  >
                                    <RIGHTSVG height={18} width={18} />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        )
                      })}
                    </ScrollView>
                  </View>
                </View>
                : null}
            </View>}
      <View style={{ backgroundColor: "#F4F5FA", height: 12 }} />
      <FooterBlack />

      <Modal
        animationType="slide"
        onRequestClose={() => {
          setSetShowQr(!showQr);
        }}
        transparent={false}
        visible={showQr}
      >
        <QRModal Details={Details} setSetShowQr={setSetShowQr} />
      </Modal>

    </View>
  );
};

export default DigitalCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F4F5FA",
  },
  textNameStyle: {
    fontSize: 20,
    fontFamily: "Poppins-SemiBold",
    textAlign: "center",
    marginTop: 40,
    color: "#3A3A3A",
  },
  textStyle: {
    fontSize: 12,
    fontFamily: "Poppins-Regular",
    textAlign: "center",
    marginTop: 10,
    color: "#989EB1",
    width: "90%",
  },
  textBtnStyle: {
    fontSize: 15,
    fontFamily: "Poppins-SemiBold",
    textAlign: "center",
    color: "#ED1D24",
    marginLeft: 10,
  },
});
