import React, { FC, useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  MECHANFO_BG,
  MECHANFO_TEXT_COLOR,
  MECHANFO_RED,
} from '../../screens/import';
import ToggleSwitch from 'toggle-switch-react-native';
// //@ts-ignore
// import TAKEAWAY from '../../assets/order/take-away.svg';
// //@ts-ignore
// import RESERVETABLE from '../../assets/order/reserve.svg';
import {
  useAppDispatch,
  useAppSelector,
} from '../../components/store/reduxHooks';
import { Cart, clearCart, selectedCouponId, setActiveOrderRestaurant, setCartCouponFlag, setCartItemTotal, setDeliveryMode, setPaymentMode } from './cartSlice';
import ItemButton from '../../components/Restaurant/ItemButton';
import {
  handleChangeItemQuantity,
  handleChangeVariantItemQuantity,
} from './CartUtils';
import {
  calculateCartTotal,
} from '../../components/Restaurant/utils/price';
import { useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import { CartEmpty } from '../../components/general/general';
import { AdOnComponent, AdOnPaynowComponent } from '../../components/general/CartComponent/InsideRestaurant';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { createOrder, getOrderHistoryList, updateOrder } from '../../components/request/orders';
import { getRestaurantMenuList, getRestaurantdetailsData } from '../../components/request/restaurant';
import { ActiveBillDetails, ExistingActiveItem } from '../../components/general/CartComponent/existingActiveOrder';
import axios from 'axios';
import { base_url } from '../../api/api';

const OrderItemScreen = ({ route, navigation }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isInside, setIsInside] = useState(false);
  const dispatch = useAppDispatch();
  const [reservationCharge, setReservationCharge] = useState<any>();
  const [gst, setGst] = useState<any>();
  const [restaurantId, setRestaurantId] = useState<any>();
  const { cartItemData, cart_restaurant, delivery_mode, activeOrderRestaurant } = useAppSelector(Cart);
  const [payNowLoader, setPayNowLoader] = useState(false);
  const [insidePayNowLoader, setInsidePayNowLoader] = useState(0);
  const [tableNo, setTableNo] = useState<any>(0);
  const [tableNoErr, setTableNoErr] = useState(false);
  const [tableNoErrMsg, setTableNoErrMsg] = useState(
    'Required a valid table number',
  );
  const [payNowRefresh, setPaynowRefresh] = useState(false);
  const [activeOrderStatus, setActiveOrderStatus] = useState(false);
  const [activeOrderList, setActiveOrderList] = useState<any>([]);
  const [currencySymbol, setCurrencySymbol] = useState<any>();
  const [restDetail, setRestDetail] = useState<any | null>(null);
  const [kotEnabled, setKotEnabled] = useState(true);
  const [token, setToken] = useState<any>();


  useEffect(() => {
    if (cart_restaurant.takeAwayEnabled == true) {
      dispatch(setDeliveryMode({ mode: 2 }));
    } else if (cart_restaurant.reservationsEnabled == true) {
      dispatch(setDeliveryMode({ mode: 3 }));
    } else if (cart_restaurant.tableOrderEnabled == true) {
      dispatch(setDeliveryMode({ mode: 1 }));
      setIsInside(true);
    }
    getBillDetails();
    getCart();
  }, [navigation, cart_restaurant]);

  const getCart = async () => {
    const user_token = await AsyncStorage.getItem('token');
    setToken(user_token);
    if (user_token) {
      getOrderHistoryList((response: any) => {
        if (response.data.activeOrders.length > 0) {
          let active_order = response.data.activeOrders;
          active_order.map((active: any) => {
            if (active.typeOfOrder == 1 && active.orderStatus != 2 && active.paymentStatus == 0) {
              setActiveOrderList(active);
              setTableNo(active.tableNo);
              dispatch(setDeliveryMode({ mode: 1 }));
              setIsInside(true);
              fetchRestaurantDetail(active.restaurantId._id);
              setActiveOrderStatus(true);
              setDataLoading(false);
            } else {
              if (Object.values(cartItemData).length > 0) {
                fetchRestaurantDetail(cart_restaurant._id);
                setDataLoading(false);
              } else {
                setDataLoading(true);
              }
              setIsLoading(false);
            }
          });
        } else {
          if (Object.values(cartItemData).length > 0) {
            fetchRestaurantDetail(cart_restaurant._id);
            setDataLoading(false);
          } else {
            setDataLoading(true);
          }
          setIsLoading(false);
        }
      });
    } else {
    }
  };

  const fetchRestaurantDetail = async (rest_id: any) => {
    let restId = rest_id;
    let user_id = await AsyncStorage.getItem('userId');
    try {
      getRestaurantdetailsData(
        (list: any) => {
          if (list.data.status === 'success') {
            setRestDetail(list.data.restaurant);
            const removeQuotes = (string: any) => string.replace(/"(.+)"/g, '');
            setCurrencySymbol(removeQuotes(list.data.restaurant.countryId.symbol));
            dispatch(setActiveOrderRestaurant({ data: list.data.restaurant }));
          }
        },
        restId,
        user_id,
      );
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
    setDataLoading(false);
  };

  const getBillDetails = async () => {
    setReservationCharge(cart_restaurant.reservationCharge);
    setGst(cart_restaurant.cgst);
    setRestaurantId(cart_restaurant.restaurantId);
  };

  const payNowButtonPress = () => {
    setPaynowRefresh(true);
  };

  const cartPayNow = async (type: any) => {
    let cart_data = {};
    if (type == 2) {
      setPayNowLoader(true);
    } else {
      setPayNowLoader(false);
    }
    dispatch(setPaymentMode({ mode: 0 }));
    dispatch(
      selectedCouponId({
        id: null,
        balance: 0,
        cartCouponIsPercentage: 0,
      }),
    );
    const user_name = await AsyncStorage.getItem('username');
    const user_phone = await AsyncStorage.getItem('phone');

    if (delivery_mode == 3 || delivery_mode == 4) {
      //@ts-ignore
      cart_data.reservationCharge = cart_restaurant.reservationCharge;
    }
    if (activeOrderStatus && Object.values(cartItemData).length > 0) {
      //@ts-ignore
      cart_data.orderId = activeOrderList._id;
      //@ts-ignore
      cart_data.item = Object.values(cartItemData);
      let item_total = calculateCartTotal(cartItemData) + activeOrderList.itemTotal;

      //@ts-ignore
      cart_data.itemTotal = item_total;
      //@ts-ignore
      cart_data.totalAmount = calculateTotal(item_total);
      //@ts-ignore
      cart_data.typeOfOrder = 1;
      //@ts-ignore
      cart_data.gstEnable = restDetail.gstEnable;
      //@ts-ignore
      cart_data.restaurantId = activeOrderList.restaurantId._id;
      //@ts-ignore
      cart_data.currency = cart_restaurant.currencyCode;
    } else if (activeOrderStatus && Object.values(cartItemData).length == 0) {
      //@ts-ignore
      cart_data.restaurantId = activeOrderList.restaurantId._id;
      //@ts-ignore
      cart_data.orderId = activeOrderList._id;
      // console.log('active order items+++++', restDetail);
      //@ts-ignore
      cart_data.item = activeOrderList.items;
      //@ts-ignore
      cart_data.itemTotal = activeOrderList.itemTotal;
      //@ts-ignore
      cart_data.totalAmount = activeOrderList.totalAmount;
      //@ts-ignore
      cart_data.typeOfOrder = 1;
      //@ts-ignore
      cart_data.gstEnable = restDetail.gstEnable;
      //@ts-ignore
      cart_data.currency = activeOrderRestaurant.countryId.currency;
    } else {
      //@ts-ignore
      cart_data.restaurantId = cart_restaurant._id;
      //@ts-ignore
      cart_data.item = Object.values(cartItemData);
      //@ts-ignore
      cart_data.itemTotal = calculateCartTotal(cartItemData);
      let total_amount = calculateTotal(calculateCartTotal(cartItemData));
      //@ts-ignore
      cart_data.totalAmount = total_amount;
      //@ts-ignore
      cart_data.typeOfOrder = delivery_mode;
      //@ts-ignore
      cart_data.gstEnable = cart_restaurant.gstEnable;
      //@ts-ignore
      cart_data.paymentMode = 0;
      //@ts-ignore
      cart_data.currency = cart_restaurant.currencyCode;
    }
    //@ts-ignore
    cart_data.cgst = calculateGst(1);
    //@ts-ignore
    cart_data.sgst = calculateGst(2);
    //@ts-ignore
    cart_data.totalGst = calculateGst(1) + calculateGst(2);
    //@ts-ignore
    cart_data.customerName = user_name;
    //@ts-ignore
    cart_data.contactNumber = user_phone;
    //@ts-ignore
    cart_data.couponId = null;
    //@ts-ignore
    cart_data.couponAmountUsed = 0;
    //@ts-ignore
    cart_data.isPercentage = 0;
    //@ts-ignore
    cart_data.amountToBePaid = 0;

    if (delivery_mode == 3) {
      //@ts-ignore
      cart_data.reservationDetails = reservationData;
    }

    if (isInside) {
      //@ts-ignore
      cart_data.tableNo = tableNo;
    } else {
      //@ts-ignore
      cart_data.tableNo = tableNo;
    }
    setPayNowLoader(false);
    setPaynowRefresh(false);
    //@ts-ignore
    // if (isInside && cart_data.tableNo == 0) {
    //   setTableNoErr(true);
    //   setTableNoErrMsg('Enter a valid table number');
    // } else {
    if (type == 1) {
      //@ts-ignore
      // PaymentMode 1 = COD
      cart_data.paymentMode = 0;
      dispatch(setCartItemTotal({ data: cart_data }));
      dispatch(
        setCartCouponFlag({
          flag: false,
        }),
      );
      //@ts-ignore
      cart_data.paymentMode = 0;

      navigation.navigate('CartBillDetails', { data: cart_data, activeOrder: activeOrderStatus ? 1 : 0, currencySymbol: currencySymbol });
    } else {
      setPayNowLoader(true);
      dispatch(setCartItemTotal({ data: cart_data }));
      if (activeOrderStatus && Object.values(cartItemData).length > 0) {
        //@ts-ignore
        cart_data.paymentMode = 6;
        try {
          // updateOrder((response) => {
          //   if (response.data.status == 'success') {
          //     dispatch(clearCart());
          //     dispatch(
          //       reserveTable({
          //         data: {},
          //       }),
          //     );
          //     dispatch(
          //       setCartCouponFlag({
          //         flag: false,
          //       }),
          //     );
          //     dispatch(setDeliveryMode({ mode: 0 }));
          //     dispatch(setPaymentMode({ mode: 0 }));
          //     navigation.reset({
          //       index: 0,
          //       routes: [
          //         {
          //           name: 'commonPayment',
          //           params: {
          //             screenName: 'viewCart',
          //           },
          //         },
          //       ],
          //     });
          //   } else {

          //   }
          // }, cart_data);
        } catch (error) {
          console.log('catch error from update order API', error);
        }
      } else {
        setPayNowLoader(false);
        //@ts-ignore
        // PaymentMode 1 = COD
        cart_data.paymentMode = 0;
        dispatch(setCartItemTotal({ data: cart_data }));
        dispatch(
          setCartCouponFlag({
            flag: false,
          }),
        );
        if (isInside && restDetail.payType == 1) {

          //@ts-ignore
          cart_data.paymentMode = 6;

          // const token = await AsyncStorage.getItem('token');
          const response = await axios
            .post(base_url + 'users/order/create', cart_data, {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // Authorization: `Bearer ${token}`,
              },
            })
            .then(response => {

              if (response.data.status == 'success') {
                dispatch(clearCart());
                dispatch(
                  setCartCouponFlag({
                    flag: false,
                  }),
                );
                dispatch(setDeliveryMode({ mode: 0 }));
                dispatch(setPaymentMode({ mode: 0 }));
                setPayNowLoader(false);
                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      name: 'commonPayment',
                      params: {
                        screenName: 'viewCart',
                      },
                    },
                  ],
                });
              } else {
                setPayNowLoader(false);
              }
            })
            .catch(err => {
              console.log('Error:', err);
            });
        } else {
          navigation.navigate('CartBillDetails', { data: cart_data, activeOrder: activeOrderStatus ? 1 : 0, currencySymbol: currencySymbol });
        }
      }

    }
    // }
  };

  const calculateTotal = (item: any) => {
    if (cart_restaurant.gstEnable || (activeOrderList.length > 0 && restDetail.gstEnable)) {
      if (delivery_mode == 1) {
        //Dine-in
        let value = item + calculateGst(1) + calculateGst(2);
        return (
          value.toFixed(2)
        );
      } else if (delivery_mode == 2) {
        //take-away
        let value = item + calculateGst(1) + calculateGst(2);
        return (
          value.toFixed(2)
        );
      } else {
        //reservation
        let value = item +
          calculateGst(1) +
          calculateGst(2) +
          cart_restaurant.reservationCharge;
        return (
          value.toFixed(2)
        );
      }
    } else {
      return item;
    }


  };

  const calculateGst = (type: any) => {
    let item_amount = 0;
    let cgst = 0;
    let sgst = 0;
    if (activeOrderStatus && Object.values(cartItemData).length > 0 && restDetail.gstEnable) {
      item_amount = activeOrderList.itemTotal + calculateCartTotal(cartItemData);
      cgst = restDetail.cgst;
      sgst = restDetail.sgst;
      if (type == 1) {
        let cgst_amt =
          (item_amount * cgst) / 100;
        return cgst_amt;
      } else {
        let sgst_amt =
          (item_amount * sgst) / 100;
        return sgst_amt;
      }
    } else if (activeOrderStatus && Object.values(cartItemData).length == 0) {
      item_amount = activeOrderList.itemTotal;
      cgst = restDetail.cgst;
      sgst = restDetail.sgst;
      if (type == 1) {
        let cgst_amt =
          (item_amount * cgst) / 100;
        return cgst_amt;
      } else {
        let sgst_amt =
          (item_amount * sgst) / 100;
        return sgst_amt;
      }
    } else {
      item_amount = calculateCartTotal(cartItemData);
      if (cart_restaurant.gstEnable || (activeOrderList.length > 0 && restDetail.gstEnable)) {
        if (delivery_mode == 2) {
          cgst = cart_restaurant.takeAwaySgst;
          sgst = cart_restaurant.takeAwayCgst;
        } else {
          cgst = cart_restaurant.cgst;
          sgst = cart_restaurant.sgst;
        }
        if (type == 1) {
          let cgst_amt =
            (item_amount * cgst) / 100;
          return cgst_amt;
        } else {
          let sgst_amt =
            (item_amount * sgst) / 100;
          return sgst_amt;
        }
      } else {
        return 0;
      }
    }
  };

  return (
    <View style={[styles.container]}>
      <StatusBar
        barStyle="dark-content"
        backgroundColor={'#fff'}
        translucent
      />
      <View style={[styles.mainContainer]}>
        <View style={{ overflow: 'hidden', paddingBottom: 5 }}>
          <View
            style={[
              {
                backgroundColor: '#fff',
                width: '100%',
                shadowColor: '#000',
                shadowOffset: { width: 1, height: 1 },
                shadowOpacity: 0.2,
                shadowRadius: 3,
                elevation: 2,
              },
            ]}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: Platform.OS === 'ios' ? 50 : 10,
                marginBottom: Platform.OS === 'ios' ? 10 : 10,
              }}>
              <TouchableOpacity style={styles.backarrowContainer}>
                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 35,
                    width: 35,
                  }}
                  onPress={() => {
                    navigation.goBack();
                  }}>
                  {/* <Image
                    style={{ height: 15, width: 20 }}
                    source={require('../../assets/general/left_arrow.png')}
                  /> */}
                </TouchableOpacity>
              </TouchableOpacity>
              <Text style={{ fontWeight: '600', fontSize: 20, color: '#000' }}>
                {activeOrderStatus ? activeOrderList.restaurantId.displayName : cart_restaurant.displayName}
              </Text>
            </View>
          </View>
        </View>
        {!isLoading && token ? (
          <View style={[styles.secondContainer]}>
            {(Object.keys(cartItemData).length > 0 || activeOrderStatus) &&
              (cart_restaurant.takeAwayEnabled == true || cart_restaurant.reservationsEnabled == true || cart_restaurant.tableOrderEnabled == true)
              || (activeOrderStatus) ?
              <>
                <ScrollView
                  style={{ flex: 1 }}
                  horizontal={false}
                  showsVerticalScrollIndicator={false}
                  contentContainerStyle={{ flexGrow: 1, paddingBottom: 100 }}>
                  <View style={{ margin: 20 }}>
                    {activeOrderStatus ?
                      <ExistingActiveItem activeOrderList={activeOrderList} currencySymbol={currencySymbol} restDetail={activeOrderRestaurant} navigation={navigation} />
                      : null}
                    {Object.values(cartItemData).length > 0 ?
                      <View style={[styles.cardContainer, { marginTop: 20 }]}>
                        {Object.values(cartItemData).map((item: any, index: any) => {
                          return (
                            <View key={item.item_id}>
                              <View style={{ overflow: 'hidden' }}>
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: 10,
                                    paddingBottom: 8,
                                  }}>
                                  <View
                                    style={{
                                      flexDirection: 'column',
                                      flex: 1,
                                    }}>
                                    <View
                                      style={[
                                        { flexDirection: 'row' },
                                        item.variantExists
                                          ? {
                                            backgroundColor: '#F4F5FA',
                                            padding: 4,
                                            alignItems: 'center',
                                          }
                                          : {},
                                      ]}>
                                      <Text
                                        style={{
                                          color: MECHANFO_TEXT_COLOR,
                                          fontSize: item.variantExists ? 12 : 14,
                                          fontWeight: '600',
                                        }}>
                                        {item.item_name}
                                      </Text>
                                    </View>
                                    {item.variantExists &&
                                      Object.values(item.variant).length > 0 ? (
                                      <View>
                                        {Object.values(item.variant).map(
                                          (vItem: any) => {
                                            return (
                                              <View
                                                style={{
                                                  flexDirection: 'row',
                                                  marginTop: 8,
                                                  justifyContent: 'space-between',
                                                }}
                                                key={vItem._id}>
                                                <View
                                                  style={{ flexDirection: 'column' }}>
                                                  <Text
                                                    style={{
                                                      color: MECHANFO_TEXT_COLOR,
                                                      fontSize: 14,
                                                      fontWeight: '600',
                                                    }}>
                                                    {vItem.variantName}
                                                  </Text>
                                                  <Text
                                                    style={{
                                                      color: MECHANFO_TEXT_COLOR,
                                                      fontSize: 15,
                                                      fontWeight: '500',
                                                    }}>
                                                    {cart_restaurant.currency}
                                                    {vItem.price}
                                                  </Text>
                                                </View>
                                                <ItemButton
                                                  count={vItem.quantity}
                                                  handleDecrement={() => {
                                                    handleChangeVariantItemQuantity(
                                                      cartItemData,
                                                      dispatch,
                                                      item,
                                                      vItem,
                                                      0,
                                                    );
                                                  }}
                                                  handleIncrement={() => {
                                                    handleChangeVariantItemQuantity(
                                                      cartItemData,
                                                      dispatch,
                                                      item,
                                                      vItem,
                                                      1,
                                                    );
                                                  }}
                                                />
                                              </View>
                                            );
                                          },
                                        )}
                                      </View>
                                    ) : null}
                                    {!item.variantExists && (
                                      <View style={{ flexDirection: 'row' }}>
                                        <Text
                                          style={{
                                            color: MECHANFO_TEXT_COLOR,
                                            fontSize: 16,
                                            fontWeight: '500',
                                          }}>
                                          {cart_restaurant.currency}
                                          {item.price}
                                        </Text>
                                      </View>
                                    )}
                                  </View>
                                  {!item.variantExists && (
                                    <View
                                      style={{
                                        flexDirection: 'column',
                                      }}>
                                      <ItemButton
                                        count={item.quantity}
                                        handleDecrement={() => {
                                          handleChangeItemQuantity(
                                            cartItemData,
                                            dispatch,
                                            item,
                                            0,
                                          );
                                        }}
                                        handleIncrement={() => {
                                          handleChangeItemQuantity(
                                            cartItemData,
                                            dispatch,
                                            item,
                                            1,
                                          );
                                        }}
                                      />
                                    </View>
                                  )}
                                </View>
                              </View>
                              {index === Object.values(cartItemData).length - 1 ? (
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: 8,
                                    width: '100%',
                                  }}>
                                  <View style={{ flexDirection: 'row' }}>
                                    <View
                                      style={{
                                        borderColor: '#F0F0F0',
                                        borderWidth: 1.5,
                                        width: '100%',
                                        marginTop: 15,
                                      }}
                                    />
                                  </View>
                                  <View style={{ flexDirection: 'row', marginTop: 15 }}>
                                    <TouchableOpacity
                                      onPress={() => {
                                        if (route.params.page == 'restaurant') {
                                          navigation.goBack();
                                        } else {
                                          navigation.navigate('RestuarantDeatil', {
                                            rest_id: cart_restaurant._id,
                                            data: cart_restaurant,
                                            item_id: undefined,
                                          })
                                        }
                                      }

                                      }>
                                      <Text
                                        style={{
                                          fontSize: 16,
                                          fontWeight: '600',
                                          color: '#ED1D24',
                                        }}>
                                        + Add more item
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          );
                        })}
                      </View>
                      : null}

                    {cart_restaurant.tableOrderEnabled ?
                      (<View style={[styles.cardContainer, { marginTop: 20 }]}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <Text
                            style={[
                              {
                                color: MECHANFO_TEXT_COLOR,
                                fontSize: 14,
                                fontWeight: '700',
                              },
                            ]}>
                            Are You Inside Restaurant?
                          </Text>
                          {/* <ToggleSwitch
                            isOn={cart_restaurant.takeAwayEnabled == true || cart_restaurant.reservationsEnabled == true ? isInside : true}
                            onColor="#ED1D24"
                            offColor="#E5E7EB"
                            size="medium"
                            disabled={
                              (activeOrderStatus ? false : cart_restaurant.takeAwayEnabled == true || cart_restaurant.reservationsEnabled == true) ? false
                                : true}
                            onToggle={isOn => {
                              console.log('changed to : ', isOn, activeOrderStatus);
                              setIsInside(isOn);
                              if (isOn) {
                                dispatch(setDeliveryMode({ mode: 1 }));
                              } else {
                                dispatch(setDeliveryMode({ mode: 2 }));
                              }
                            }}
                          /> */}
                        </View>
                        {isInside ? (
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 20,
                            }}>
                            <Text>Table No</Text>
                            <TextInput
                              editable={activeOrderStatus ? false : true}
                              selectTextOnFocus={activeOrderStatus ? false : true}
                              style={[styles.textInputStyle, { width: '70%' }]}
                              onChangeText={tNo => {
                                setTableNoErr(false);
                                let num = tNo.replace(/[^0-9]/g, '');
                                if (num === '' || /^\d+$/.test(num.toString())) {
                                  // Its not a number
                                  setTableNo(num);
                                } else {
                                  setTableNo(tNo);
                                  setTableNoErr(false);
                                }
                              }}
                              value={tableNo}
                              maxLength={3}
                              autoCapitalize="none"
                              keyboardType="numeric"
                            />
                          </View>
                        ) : null}
                        {tableNoErr ? (
                          <View
                            style={{
                              justifyContent: 'center',
                              marginTop: 10,
                            }}>
                            <Text
                              style={{
                                color: MECHANFO_RED,
                                fontWeight: '500',
                              }}>
                              {tableNoErrMsg}
                            </Text>
                          </View>
                        ) : null}
                        {cart_restaurant.takeAwayEnabled == false && cart_restaurant.reservationsEnabled == false && cart_restaurant.tableOrderEnabled ? (
                          <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                              justifyContent: 'space-between',
                              marginTop: 10,
                            }}>
                            <Text
                              style={{
                                color: '#555555',
                                fontSize: 12,
                                fontWeight: '400',
                              }}>
                              You can only order food, if you are inside the restaurant/ near by mall
                            </Text>
                          </View>
                        ) : null}
                      </View>) : null}
                    {!isInside && (cart_restaurant.reservationsEnabled == true || cart_restaurant.takeAwayEnabled == true) ? (
                      <View style={[styles.cardContainer, { marginTop: 20 }]}>
                        <View style={{ flexDirection: 'row' }}>
                          <Text
                            style={{
                              color: MECHANFO_TEXT_COLOR,
                              fontSize: 14,
                              fontWeight: '600',
                            }}>
                            Delivery Mode
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: 20,
                          }}>
                          {cart_restaurant.takeAwayEnabled == true ?
                            (<TouchableOpacity
                              onPress={() => {
                                dispatch(setDeliveryMode({ mode: 2 }));
                              }}
                              style={[
                                styles.deleveryBoxColumn,
                                {
                                  alignItems: 'flex-start',
                                  borderColor:
                                    delivery_mode === 2 ? '#04A39B' : '#F4F5FA',
                                },
                              ]}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  width: '100%',
                                  flexWrap: 'wrap',
                                }}>
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    width: '38%',
                                  }}>
                                  {/* <TAKEAWAY width={50} height={50} /> */}
                                </View>
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    width: '55%',
                                  }}>
                                  <Text
                                    style={[
                                      styles.deliveryBoxText,
                                      { paddingLeft: 5 },
                                    ]}>
                                    Take Away Or Parcel
                                  </Text>
                                </View>
                              </View>
                            </TouchableOpacity>) : null}
                          {cart_restaurant.reservationsEnabled == true ?
                            (<TouchableOpacity
                              onPress={() => {
                                setTimeout(() => {
                                  navigation.navigate('reserveTable', {
                                    restaurantName: cart_restaurant.displayName,
                                  });
                                }, 500);
                              }}
                              style={[
                                styles.deleveryBoxColumn,
                                {
                                  alignItems: 'flex-end',
                                  borderColor:
                                    delivery_mode === 3 ? '#04A39B' : '#F4F5FA',
                                },
                              ]}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  width: '100%',
                                  flexWrap: 'wrap',
                                }}>
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    width: '38%',
                                  }}>
                                  {/* <RESERVETABLE width={50} height={50} /> */}
                                </View>
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    width: '55%',
                                    paddingLeft: 5,
                                  }}>
                                  <Text style={[styles.deliveryBoxText]}>
                                    Reserve a
                                  </Text>
                                  <Text style={[styles.deliveryBoxText]}>Table</Text>
                                </View>
                              </View>
                            </TouchableOpacity>) : null}
                        </View>
                        {delivery_mode == 2 && (
                          <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                              justifyContent: 'space-between',
                              marginTop: 10,
                            }}>
                            <Text
                              style={{
                                color: '#555555',
                                fontSize: 12,
                                fontWeight: '400',
                              }}>
                              You will get live Notification from Restaurant, when
                              food is ready to pick up
                            </Text>
                          </View>
                        )}
                      </View>
                    ) : null}
                    {Object.values(cartItemData).length != 0 && activeOrderStatus ?
                      (<ActiveBillDetails activeOrderList={activeOrderList} currencySymbol={currencySymbol} restDetail={cart_restaurant} type={2} />)
                      : Object.values(cartItemData).length != 0 ? (
                        <View style={[styles.cardContainer, { marginTop: 20 }]}>
                          <View style={{ flexDirection: 'column' }}>
                            <Text style={[styles.billTitleText]}>Bill Details</Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                width: '100%',
                                marginTop: 10,
                              }}>
                              <View
                                style={{
                                  flexDirection: 'column',
                                  width: '60%',
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                }}>
                                <Text style={[styles.billDetailText]}>Item Total</Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: 'column',
                                  width: '38%',
                                  justifyContent: 'center',
                                  alignItems: 'flex-end',
                                }}>
                                <Text style={[styles.billPriceText]}>
                                  {cart_restaurant.currency}
                                  {calculateCartTotal(cartItemData)}
                                </Text>
                              </View>
                            </View>
                            {(cart_restaurant.gstEnable || (activeOrderList.length > 0 && restDetail.gstEnable)) ?
                              (<><View
                                style={{
                                  flexDirection: 'row',
                                  width: '100%',
                                  marginTop: 10,
                                }}>
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    width: '60%',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                  }}>
                                  <Text style={[styles.billDetailText]}>
                                    CGST Amount ({cart_restaurant.cgst}%)
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    width: '38%',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                  }}>
                                  <Text style={[styles.billPriceText]}>
                                    {cart_restaurant.currency}
                                    {calculateGst(1)}
                                  </Text>
                                </View>
                              </View>
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginTop: 10,
                                  }}>
                                  <View
                                    style={{
                                      flexDirection: 'column',
                                      width: '60%',
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                    }}>
                                    <Text style={[styles.billDetailText]}>
                                      SGST Amount ({cart_restaurant.sgst}%)
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: 'column',
                                      width: '38%',
                                      justifyContent: 'center',
                                      alignItems: 'flex-end',
                                    }}>
                                    <Text style={[styles.billPriceText]}>
                                      {cart_restaurant.currency}
                                      {calculateGst(2)}
                                    </Text>
                                  </View>
                                </View></>) : null}
                            <View style={{ overflow: 'hidden', marginTop: 10 }}>
                              <View
                                style={{
                                  borderStyle: 'dashed',
                                  borderWidth: 1,
                                  borderColor: '#B4B4B4',
                                  margin: -1,
                                  height: 0,
                                  marginBottom: 0,
                                }}>
                                <View style={{ width: 60 }}></View>
                              </View>
                            </View>
                            <View
                              style={{
                                flexDirection: 'row',
                                width: '100%',
                                marginTop: 10,
                              }}>
                              <View
                                style={{
                                  flexDirection: 'column',
                                  width: '60%',
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                }}>
                                <View style={{ flexDirection: 'row' }}>
                                  <Text
                                    style={[styles.billDetailText, { fontWeight: '600' }]}>
                                    Total{' '}
                                  </Text>
                                  {(cart_restaurant.gstEnable || (activeOrderList.length > 0 && restDetail.gstEnable)) ?
                                    null : (<Text
                                      style={[styles.billDetailText, { fontWeight: '400' }]}>
                                      (Gst Included)
                                    </Text>)}
                                </View>
                              </View>
                              <View
                                style={{
                                  flexDirection: 'column',
                                  width: '38%',
                                  justifyContent: 'center',
                                  alignItems: 'flex-end',
                                }}>
                                <Text
                                  style={[
                                    styles.billPriceText,
                                    { fontSize: 14, fontWeight: '600' },
                                  ]}>
                                  {cart_restaurant.currency} {calculateTotal(activeOrderStatus ? activeOrderList.itemTotal : calculateCartTotal(cartItemData))}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      ) :
                        activeOrderStatus ?
                          (<ActiveBillDetails activeOrderList={activeOrderList} currencySymbol={currencySymbol} restDetail={cart_restaurant} type={1} />) : null}
                    {/* {isInside ? (<View style={[{ marginTop: 10, flexWrap: 'wrap' }]}>
                      <AdOnComponent /> </View>) : null} */}
                    {isInside ? <View style={[{ marginTop: 10, flexWrap: 'wrap' }]}>
                      {(Object.values(cartItemData).length == 0 && activeOrderStatus && activeOrderList.orderStatus == 3) ?
                        (<>
                          <AdOnComponent />
                          <AdOnPaynowComponent payNowButtonPress={payNowButtonPress} cartPayNowButton={cartPayNow} tableNo={tableNo} />
                        </>) :
                        <AdOnComponent />}
                    </View> : null}
                    {isInside && activeOrderStatus && (activeOrderList.orderStatus == 0) ? (
                      <Text style={{ color: MECHANFO_RED, fontSize: 12, paddingLeft: 10, marginTop: 20, fontWeight: '600' }}>
                        Waiting for the restaurant to accept this order
                      </Text>)
                      : null}
                  </View>
                </ScrollView>
                {activeOrderStatus && Object.values(cartItemData).length == 0 ? null :
                  (<View
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: '#fff',
                      flexDirection: 'row',
                      paddingTop: 20,
                    }}>
                    <TouchableOpacity
                      onPress={() => cartPayNow(2)}
                      disabled={payNowLoader ? true : false}
                      style={{
                        width: '90%',
                        marginLeft: 18.75,
                        marginRight: 18.75,
                        marginBottom: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: 18,
                        backgroundColor: '#ED1D24',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      {payNowLoader ? (
                        <ActivityIndicator size="large" color="#fff" />
                      ) : (
                        <Text
                          style={{ fontSize: 24, fontWeight: '600', color: '#fff' }}>
                          {isInside && activeOrderStatus && Object.values(cartItemData).length > 0 ? 'Update Order'
                            : isInside && Object.values(cartItemData).length > 0 && (restDetail.payType == 1 || restDetail.payType == true) ? 'Send Order'
                              : isInside && Object.values(cartItemData).length > 0 && (restDetail.payType == null || restDetail.payType != 1) ? 'Continue'
                                : !isInside && Object.values(cartItemData).length > 0 ? 'Continue'
                                  : null}
                        </Text>
                      )}
                    </TouchableOpacity>
                  </View>)}
              </>
              : (
                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 17.67, backgroundColor: '#fff', borderRadius: 18 }}>
                  <CartEmpty />
                </View>)}
          </View>
        ) : dataLoading && token ? (
          <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 17.67, backgroundColor: '#fff', borderRadius: 18 }}>
            <CartEmpty />
          </View>) : token == null ?
          (<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 50, }}>
            {/* <Image
              source={require('../../assets/profile/firstLogin.png')}
              style={{ height: 166.36, width: 78.77 }}
            /> */}
            <Text
              style={{
                fontWeight: '600',
                fontSize: 16,
                color: '#000',
                marginTop: 20,
              }}>
              Please login first
            </Text>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate('LoginHomeStack', { screen: 'Login' })
              }
              style={[
                styles.SubmitButton,
                styles.shadowProp,
                {
                  marginTop: 20,
                  shadowColor:
                    Platform.OS === 'ios' ? MECHANFO_RED : MECHANFO_RED,
                },
              ]}>
              <Text
                style={[{ color: '#fff', fontSize: 16, fontWeight: '600' }]}>
                {'Login'}
              </Text>
            </TouchableOpacity>
          </View>)
          : (
            <View
              style={{
                flex: 1,
                backgroundColor: MECHANFO_BG,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <ActivityIndicator
                animating={animating}
                color={MECHANFO_RED}
                size="large"
                style={styles.activityIndicator}
              />
            </View>
          )}
      </View>
    </View >
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingTop: StatusBar.currentHeight,
  },
  mainContainer: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: MECHANFO_BG,
  },
  secondContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  backarrowContainer: {
    width: 40,
    marginLeft: 20,
  },
  activityIndicator: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  },
  cardContainer: {
    backgroundColor: '#fff',
    borderRadius: 18,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  deleveryBoxColumn: {
    flexDirection: 'column',
    width: '48%',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 15,
    padding: 10,
    backgroundColor: '#F4F5FA',
    marginBottom: 10,
  },
  deliveryBoxText: {
    color: MECHANFO_TEXT_COLOR,
    fontSize: 14,
    fontWeight: '600',
  },
  billDetailText: {
    color: MECHANFO_TEXT_COLOR,
    fontSize: 14,
    fontWeight: '400',
  },
  billTitleText: {
    color: MECHANFO_TEXT_COLOR,
    fontSize: 14,
    fontWeight: '600',
  },
  billPriceText: {
    color: MECHANFO_TEXT_COLOR,
    fontSize: 16,
    fontWeight: '500',
  },
  textInputStyle: {
    borderRadius: 10,
    backgroundColor: '#F4F5F7',
    paddingLeft: 17.81,
    color: '#000',
    paddingTop: Platform.OS === 'ios' ? 16.41 : 0,
    paddingBottom: Platform.OS === 'ios' ? 16.41 : 0,
    height: 45,
    justifyContent: 'center',
    width: '90%',
    marginTop: 10,
  },
  SubmitButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: MECHANFO_RED,
    height: 54,
    borderRadius: 4,
    width: 158,
  },
  shadowProp: {
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    // shadowColor: 'red',
    shadowOffset: { width: -2, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: Platform.OS === 'ios' ? 0.2 : 10,
  },
});

export default OrderItemScreen;
