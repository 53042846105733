import * as React from "react"
const SaveSvg = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#EC1D23"
      d="M15.26 4.847H9.68c-4.42 0-4.42 2.35-4.42 4.42v3.79c0 .22.1.42.27.55.17.13.4.18.61.12.45-.12 1.01-.18 1.68-.18.67 0 .81.08 1.21.38l.91.96a3.48 3.48 0 0 0 5.08 0l.91-.96c.4-.3.54-.38 1.21-.38.67 0 1.23.06 1.68.18.21.06.43.01.61-.12.17-.13.27-.34.27-.55v-3.79c-.02-2.07-.02-4.42-4.44-4.42Z"
      opacity={0.4}
    />
    <path
      fill="#EC1D23"
      d="M14.744 7.647a.676.676 0 0 0-.95 0l-.65.65v-4.78c0-.37-.31-.67-.68-.67-.37 0-.68.3-.68.67v4.77l-.64-.64a.676.676 0 0 0-.95 0c-.26.26-.26.69 0 .95l1.8 1.8c.01.01.01.01.02.01.06.05.12.1.2.13.08.03.17.05.26.05s.17-.02.26-.05c.08-.03.16-.08.22-.15l1.8-1.8c.25-.25.25-.68-.01-.94ZM19.16 12.377c-.57-.15-1.24-.23-2.04-.23-1.11 0-1.52.27-2.09.7-.03.02-.06.05-.09.08l-.95 1.01c-.8.84-2.24.85-3.04-.01l-.95-1a.647.647 0 0 0-.09-.08c-.57-.43-.98-.7-2.09-.7-.8 0-1.47.08-2.04.23-2.38.64-2.38 2.53-2.38 4.19v.93c0 2.51 0 5.35 5.35 5.35h7.44c3.55 0 5.35-1.8 5.35-5.35v-.93c0-1.66 0-3.55-2.38-4.19Zm-4.36 6.87h-4.66c-.38 0-.69-.31-.69-.7 0-.39.31-.7.69-.7h4.66c.38 0 .69.31.69.7 0 .39-.31.7-.69.7Z"
    />
    <path
      fill="#EC1D23"
      d="M15.49 18.547c0 .39-.31.7-.69.7h-4.66c-.38 0-.69-.31-.69-.7 0-.39.31-.7.69-.7h4.66c.38 0 .69.31.69.7Z"
      opacity={0.4}
    />
  </svg>
)
export default SaveSvg
