import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import React, { useEffect, useRef } from "react";

type Props = {
  Details: any;
  setSetShowQr: any
};

import QRCodeStyling from "qr-code-styling";
import MailSvg from "./assets/Mail";
import CallSVG from "./assets/Call";
import QRoptions from './QrOptions.json'
import DownloadICon from "./assets/DownloadICon";

//@ts-ignore
const qrCode = new QRCodeStyling(QRoptions);

const QRModal = ({ Details, setSetShowQr }: Props) => {
  const { height, width } = useWindowDimensions();


  const QR_DATA = window.location.href

  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      qrCode.append(ref.current);
    }
  }, []);

  useEffect(() => {
    qrCode.update({
      data: QR_DATA
    });
  }, [QR_DATA]);

  const onDownloadClick = () => {
    qrCode.download({
      extension: 'png'
    });
  };

  return (
    <View>
      <View>
        <Image
          style={{
            borderBottomLeftRadius: 18,
            borderBottomRightRadius: 18,
            width: width,
            height: 200,
          }}
          source={{ uri: Details.bgImage }}
        ></Image>
        <Image
          style={{
            width: 125,
            height: 125,
            position: "absolute",
            top: 110,
            left: (width - 125) / 2,
            borderWidth: 5,
            borderColor: "#fff",
            borderRadius: 100,
          }}
          source={{ uri: Details.logo }}
          // source={require('../../../assets/general/m.png')}
          resizeMode="cover"
        />
      </View>
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={[styles.textNameStyle]}>{Details.name}</Text>
        </View>
      </View>
      <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
        <div ref={ref} />
      </View>


      <View
        style={{
          flexDirection: "row",
          paddingVertical: 12,
          backgroundColor: "#fff",
          alignItems: "center",
          marginTop: 12,
          justifyContent: "center",
        }}
      >

        <TouchableOpacity
          style={{
            borderWidth: 1,
            borderColor: "#F4F5FA",
            borderRadius: 30,
            paddingHorizontal: 24,
            paddingVertical: 16,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={onDownloadClick}
        >
          <DownloadICon />
          <Text style={[styles.textBtnStyle]}>
            {"Download QR Code"}
          </Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity style={{ padding: 6 }} onPress={() => {

      }}>
        <Text style={[styles.textBtnStyle, { color: "#3a3a3a" }]} onPress={() => { setSetShowQr(false) }}>
          {"Close"}
        </Text>
      </TouchableOpacity>

    </View>
  );
};

export default QRModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F4F5FA",
  },
  textNameStyle: {
    fontSize: 20,
    fontFamily: "Poppins-SemiBold",
    textAlign: "center",
    marginTop: 40,
    color: "#3A3A3A",
  },
  textStyle: {
    fontSize: 12,
    fontFamily: "Poppins-Regular",
    textAlign: "center",
    marginTop: 10,
    color: "#989EB1",
    width: "90%",
  },
  textBtnStyle: {
    fontSize: 15,
    fontFamily: "Poppins-SemiBold",
    textAlign: "center",
    color: "#ED1D24",
    marginLeft: 10,
  },
});
