// import * as Linking from 'expo-linking'; 

import { Linking } from "react-native";

export const OpenAppRestaurantPage = (rest_id: string) => {

  if(getMobileOperatingSystem() == 'ios'){

    Linking.openURL(
      "https://apps.apple.com/in/app/mechanfo-eats/id6444594193"
    ); 

  }else if(getMobileOperatingSystem() == 'android'){
    Linking.openURL(
      "https://play.google.com/store/apps/details?id=com.mechanfo.pro.food"
    );
  }else{
    Linking.openURL(
      "https://play.google.com/store/apps/details?id=com.mechanfo.pro.food"
    );
  }


  // try {
  //   Linking.openURL(`eatsmenu://menu/${rest_id}`).then(((res:any) => {
  //       // console.log(res , "RES");
  //   })).catch((err) => {
  //       // console.log(err);
  //   })
  // } catch (error) {
  //       // console.log(error);
  // }
} 


export function getMobileOperatingSystem() {

  //@ts-ignore

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "windows";
  }

  if (/android/i.test(userAgent)) {
      return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //@ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
  }

  return "unknown";
}