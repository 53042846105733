import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const QRIcon = (props: SvgProps) => (
  <Svg
  //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <Path
      fill="#3A3A3A"
      d="M1.04 7.787c-.546 0-.91-.364-.91-.91V3.235C.13 1.687 1.313.504 2.86.504h3.642c.546 0 .91.364.91.91s-.364.91-.91.91H2.861c-.546 0-.91.365-.91.911v3.642c0 .546-.364.91-.91.91Zm18.21 0c-.547 0-.91-.364-.91-.91V3.235c0-.546-.365-.91-.911-.91h-3.642c-.546 0-.91-.365-.91-.91 0-.547.364-.911.91-.911h3.642c1.547 0 2.731 1.183 2.731 2.731v3.642c0 .546-.364.91-.91.91ZM6.503 20.534H2.861C1.313 20.534.13 19.35.13 17.802v-3.641c0-.547.364-.91.91-.91.547 0 .91.363.91.91v3.641c0 .547.365.911.911.911h3.642c.546 0 .91.364.91.91 0 .547-.364.91-.91.91Zm10.926 0h-3.642c-.546 0-.91-.364-.91-.91 0-.547.364-.911.91-.911h3.642c.546 0 .91-.364.91-.91V14.16c0-.547.364-.91.91-.91.547 0 .911.363.911.91v3.641c0 1.548-1.184 2.732-2.731 2.732Z"
    />
    <Path
      fill="#3A3A3A"
      d="M8.324 9.608H4.682c-.546 0-.91-.364-.91-.91V5.056c0-.546.364-.91.91-.91h3.642c.546 0 .91.364.91.91v3.642c0 .546-.364.91-.91.91Zm-2.731-1.82h1.82V5.965h-1.82v1.821Zm2.731 9.104H4.682c-.546 0-.91-.364-.91-.91V12.34c0-.546.364-.91.91-.91h3.642c.546 0 .91.364.91.91v3.642c0 .546-.364.91-.91.91Zm-2.731-1.82h1.82V13.25h-1.82v1.821Zm10.015-5.464h-3.642c-.546 0-.91-.364-.91-.91V5.056c0-.546.364-.91.91-.91h3.642c.546 0 .91.364.91.91v3.642c0 .546-.364.91-.91.91Zm-2.732-1.82h1.821V5.965h-1.82v1.821Zm-.91 6.373c-.546 0-.91-.365-.91-.91v-.911c0-.547.364-.91.91-.91s.91.363.91.91v.91c0 .546-.364.91-.91.91Zm3.642-.91h-.91c-.547 0-.911-.365-.911-.911s.364-.91.91-.91h.91c.547 0 .911.364.911.91s-.364.91-.91.91Zm0 3.641h-2.732c-.546 0-.91-.364-.91-.91 0-.547.364-.91.91-.91h2.732c.546 0 .91.363.91.91 0 .546-.364.91-.91.91Z"
    />
  </Svg>
)
export default QRIcon
