import * as React from "react"
import Svg, { Path } from "react-native-svg"
const ArrowRight = (props: any) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={20}
        fill="none"
        {...props}
    >
        <Path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3.084}
            d="m11.1 2.318 7.71 7.71-7.71 7.71M18.81 10.028H2.408"
        />
    </Svg>
)
export default ArrowRight